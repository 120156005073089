import { Colors } from "../../materials"
export const CHECKER_BG_PROPS = {
  background: Colors.oc.gray[2],
  backgroundImage: `
    linear-gradient(
      45deg,
      ${Colors.oc.gray[6]} 25%,
      transparent 25%
    ),
    linear-gradient(
      -45deg,
      ${Colors.oc.gray[6]}
      25%,
      transparent 25%
    ),
    linear-gradient(
      45deg,
      transparent 75%,
      ${Colors.oc.gray[6]} 75%
    ),
    linear-gradient(
      -45deg,
      transparent 75%,
      ${Colors.oc.gray[6]} 75%
    )`,
  backgroundSize: `20px 20px`,
  backgroundPosition: `0 0, 0 10px, 10px -10px, -10px 0px`,
}
