import * as React from "react"
import { Box, Text, TextBold, ButtonGatsby } from "../../elements"
import { Colors, Icons } from "../../materials"

export const ConsultationTeaserMinimal: React.FC<{}> = () => (
  <Box
    borderRadius={10}
    border={`2px solid ${Colors.oc.gray[3]}`}
    bg={Colors.oc.gray[0]}
    px={6}
    pb={5}
    pt={6}
    css={{ position: "relative" }}
  >
    <Box
      css={{
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
      width={48}
      height={48}
      border={`2px solid ${Colors.oc.blue[9]}`}
      bg={Colors.oc.blue[0]}
      color={Colors.oc.blue[9]}
      borderRadius="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Icons.SvgPhoneActionsWait
        css={{ width: 24, height: 24, g: { strokeWidth: 2 } }}
      />
    </Box>
    <TextBold color={Colors.oc.blue[9]} textAlign="center">
      The first consultation is on us.
    </TextBold>
    <Box mt={1} maxWidth="copy">
      <Text color={Colors.oc.blue[9]} textAlign="center">
        We're all ears for 45 minutes.
      </Text>
    </Box>
    <Box mt={4} />
    <Box display="flex" justifyContent="center">
      <ButtonGatsby to="/free-accessibility-consultation/">
        Schedule Free Session
      </ButtonGatsby>
    </Box>
  </Box>
)
