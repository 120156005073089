import * as React from "react"

import styled from "@emotion/styled"
import { Colors } from "../../materials"

interface Props {
  isActive?: boolean
}

const WIDTH = 30
const LAYER_HEIGHT = 3
const LAYER_SPACING = 6
const COLOR = "white"

const Button = styled.button<Props>({
  // BASE
  font: "inherit",
  color: Colors.oc.white,
  textTransform: "none",
  backgroundColor: "transparent",
  border: 0,
  margin: 0,
  overflow: "visible",

  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "44px",
  minWidth: "44px",
  outline: "none",

  "&:focus-within": {
    boxShadow: `0px 0px 0px 2px ${Colors.oc.orange[7]}`,
    background: Colors.oc.orange[7],
  },
  "&:focus:not(.focus-visible)": {
    boxShadow: "none",
    background: "transparent",
  },

  ".hamburger-box": {
    width: WIDTH,
    height: LAYER_HEIGHT * 3 + LAYER_SPACING * 2,
    display: "inline-block",
    position: "relative",
    color: "inherit",
  },
  // INNER

  "& .hamburger-inner": {
    // BASE
    display: "block",
    top: "50%",
    marginTop: LAYER_HEIGHT / -2,
    color: "inherit",

    // SQUEEZE
    transitionDuration: "0.075s",
    transitionTimingFunction: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
  },
  "&.is-active .hamburger-inner": {
    transform: "rotate(45deg)",
    transformOrigin: "center center",
    transitionDelay: "0.12s",
    transitionTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
  },

  "& .hamburger-inner, & .hamburger-inner::before, & .hamburger-inner::after": {
    width: WIDTH,
    height: LAYER_HEIGHT,
    backgroundColor: "currentColor",
    position: "absolute",
    borderRadius: 4,
    transitionProperty: "transform",
    transitionDuration: "0.15s",
    transitionTimingFunction: "ease",
  },

  // Pseudo-elements
  "& .hamburger-inner::before, & .hamburger-inner::after": {
    content: "''",
    display: "block",
  },

  // BEFORE
  "& .hamburger-inner::before": {
    top: (LAYER_SPACING + LAYER_HEIGHT) * -1,
    transition: `
      top 0.075s 0.12s ease,
  opacity 0.075s ease
    `,
  },
  "&.is-active .hamburger-inner::before": {
    top: 0,
    opacity: 0,
    transition: `
      top 0.075s ease,
  opacity 0.075s 0.12s ease
    `,
  },

  // AFTER
  "& .hamburger-inner::after": {
    bottom: (LAYER_SPACING + LAYER_HEIGHT) * -1,
    transition: `
      bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19)
    `,
  },

  "&.is-active .hamburger-inner::after": {
    bottom: 0,
    transform: "rotate(-90deg)",
    transformOrigin: "center center",
    transition: `
      bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)
    `,
  },
})

export const ButtonMenu: React.FC<
  Props & React.PropsWithRef<JSX.IntrinsicElements["button"]>
> = ({ isActive, ...rest }) => (
  <Button className={isActive ? "is-active" : ""} {...rest}>
    <div className="hamburger-box">
      <div className="hamburger-inner" />
    </div>
    <span className="visually-hidden">Menu</span>
  </Button>
)
