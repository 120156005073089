import React from "react"

const SvgLoveIt = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 21.844l-9.588-10a5.672 5.672 0 01-1.063-6.551h0a5.673 5.673 0 019.085-1.474L12 5.384l1.566-1.565a5.673 5.673 0 019.085 1.474h0a5.673 5.673 0 01-1.062 6.548z"
    />
  </svg>
)

export default SvgLoveIt
