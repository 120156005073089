import React from "react"

const SvgIcSend = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" fill="currentColor" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

export default SvgIcSend
