import * as React from "react"
import styled, { CSSObject } from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"
import {
  display,
  DisplayProps,
  color,
  ColorProps,
  fontFamily,
  FontFamilyProps,
  fontSize,
  FontSizeProps,
  fontStyle,
  FontStyleProps,
  fontWeight,
  FontWeightProps,
  letterSpacing,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from "styled-system"
import { Colors, Fonts } from "../../materials"

export type TextProps = DisplayProps &
  ColorProps &
  SpaceProps &
  FontSizeProps &
  FontStyleProps &
  FontFamilyProps &
  TextAlignProps &
  LineHeightProps &
  FontWeightProps &
  LetterSpacingProps

// TODO:
// @ts-ignore
export const TextSizeless = styled("p", { shouldForwardProp })<TextProps>(
  {
    fontFamily: Fonts.sans.family,
    fontWeight: Fonts.sans.weight,
    lineHeight: 1.5,
  },
  display,
  color,
  space,
  fontSize,
  fontStyle,
  fontFamily,
  textAlign,
  lineHeight,
  fontWeight,
  letterSpacing
)
type Props = React.ComponentProps<typeof TextSizeless>
export const Text: React.FC<Props> = props => (
  <TextSizeless fontSize={[2, 3]} {...props} />
)

export const TextBold: React.FC<Props> = ({ css = {}, ...rest }) => (
  <Text
    as="p"
    fontSize={3}
    css={{
      ...(css || {}),
      fontFamily: Fonts.sansBold.family,
      fontWeight: Fonts.sansBold.weight,
    }}
    {...rest}
  />
)
export const Lead: React.FC<Props> = props => (
  <Text fontSize={[3, 4, 5]} {...props} />
)

export const Heading: React.FC<Props> = ({ css = {}, ...rest }) => (
  <TextBold
    as="h2"
    fontSize={[6, 6, 6]}
    lineHeight={1.1}
    css={{
      fontFamily: Fonts.sansBold.family,
      fontWeight: Fonts.sansBold.weight,
      ...css,
    }}
    {...rest}
  />
)

export const SubHeading = props => (
  <TextBold as="h3" fontSize={[4, 4, 5]} lineHeight={1.1} {...props} />
)

export const Title = props => (
  <Heading
    as="h1"
    color={Colors.tw.gray["800"]}
    fontSize={[7, 8, 9]}
    css={{
      strong: {
        color: Colors.oc.blue[9],
        fontWeight: "inherit",
      },
    }}
    {...props}
  />
)

export const Label = ({ css = {}, ...rest }) => (
  <TextBold
    fontSize={1}
    color={Colors.oc.gray[7]}
    letterSpacing="0.05em"
    css={{
      textTransform: "uppercase",
      ...css,
    }}
    {...rest}
  />
)
