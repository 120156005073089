import { graphql, StaticQuery } from "gatsby"
import * as React from "react"
import Helmet from "react-helmet"
import { JsonLd, Thing } from "react-schemaorg"
import { WebPage } from "schema-dts"

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const defaultJsonLd: WebPage = {
  "@type": "WebPage",
  url: "https://www.aditus.io/",
  name: "The accessibility tool for your team | Aditus",
  description: "Accessibility Testing",
}

interface Props {
  title: string
  description: string
  keywords: string
  jsonLd?: Thing
}

export const SEO: React.FunctionComponent<Props> = ({
  title,
  description,
  keywords,
  jsonLd,
  children,
}) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
          title={title || data.site.siteMetadata.title}
        >
          <meta property="fb:app_id" content="824671391240758" />
          <meta property="og:site_name" content="Aditus" />
          <meta property="og:locale" content="en_US" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@aditus_a11y" />
          <meta property="og:type" content="website" />
          <link rel="dns-prefetch" href="//www.google-analytics.com" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1,viewport-fit=cover"
          />
          <meta
            name="description"
            content={description || data.site.siteMetadata.description}
          />
          <meta
            name="keywords"
            content={keywords || data.site.siteMetadata.keywords}
          />
          {children ? children : null}
        </Helmet>
        <JsonLd<WebPage>
          item={{
            "@context": "https://schema.org",
            ...(jsonLd || defaultJsonLd),
          }}
        />
      </>
    )}
  />
)
