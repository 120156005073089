import React from "react"

const SvgHand = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <style>
        {
          ".hand_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}"
        }
      </style>
    </defs>
    <path
      className="hand_svg__a"
      d="M16.5 11.247v-1.5a1.5 1.5 0 00-3 0V9a1.5 1.5 0 00-3 0V3a1.5 1.5 0 00-3 0v9a3 3 0 00-3 3 7.017 7.017 0 001.308 4.081l1.1 1.535a4.5 4.5 0 003.658 1.884h3.813a5.121 5.121 0 005.121-5.124v-6.129a1.5 1.5 0 00-3 0z"
    />
    <path className="hand_svg__a" d="M7.5 11.997v2.25" />
    <path className="hand_svg__a" d="M10.5 11.247v-1.5" />
    <path className="hand_svg__a" d="M13.5 11.247v-1.5" />
    <path className="hand_svg__a" d="M16.5 11.247" />
  </svg>
)

export default SvgHand
