import React from "react"

const SvgArrowLeft = props => (
  <svg id="arrow-left_svg__Regular" viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <path d="M23.25 12H.75" />
    <path d="M11.25 1.5L.75 12l10.5 10.5" />
  </svg>
)

export default SvgArrowLeft
