export interface Shadows {
  [key: number]: string
}

export const Shadows: Shadows = {
  1: `
    0 1px 3px 0 rgba(0, 0, 0, .12),
    0 1px 2px 0 rgba(0, 0, 0, .24)
  `,
  2: `
    0 3px 6px 0 rgba(0, 0, 0, .15),
    0 2px 4px 0 rgba(0, 0, 0, .12)
  `,
  3: `
    0 10px 20px 0 rgba(0, 0, 0, .15),
    0 3px 6px 0 rgba(0, 0, 0, .10)
  `,
  4: `
    0 15px 25px 0 rgba(0, 0, 0, .15),
    0 5px 10px 0 rgba(0, 0, 0, .10)
  `,
  5: `
    0 20px 40px 0 rgba(0, 0, 0, .2)
  `,
}
