import * as defaultConfig from "tailwindcss/stubs/defaultConfig.stub"
interface ColorsType {
  [key: string]: string | ColorsType
}

export const errorColors: ColorsType = {
  1: "hsl(360, 92%, 20%)",
  2: "hsl(360, 85%, 25%)",
  3: "hsl(360, 79%, 32%)",
  4: "hsl(360, 72%, 38%)",
  5: "hsl(360, 67%, 44%)",
  6: "hsl(360, 64%, 55%)",
  7: "hsl(360, 71%, 66%)",
  8: "hsl(360, 77%, 78%)",
  9: "hsl(360, 82%, 89%)",
  10: "hsl(360, 100%, 97%)",
}

export const coolGrayColors: ColorsType = {
  1: "hsl(209, 61%, 16%)",
  2: "hsl(211, 39%, 23%)",
  3: "hsl(209, 34%, 30%)",
  4: "hsl(209, 28%, 39%)",
  5: "hsl(210, 22%, 49%)",
  6: "hsl(209, 23%, 60%)",
  7: "hsl(211, 27%, 70%)",
  8: "hsl(210, 31%, 80%)",
  9: "hsl(212, 33%, 89%)",
  10: "hsl(210, 36%, 96%)",
}

const neutralGrayColors: ColorsType = {
  1: "hsl(0, 0%, 28%)",
  2: "hsl(0, 0%, 35%)",
  3: "hsl(0, 0%, 43%)",
  4: "hsl(0, 0%, 50%)",
  5: "hsl(0, 0%, 58%)",
  6: "hsl(0, 0%, 65%)",
  7: "hsl(0, 0%, 76%)",
  8: "hsl(0, 0%, 83%)",
  9: "hsl(0, 0%, 91%)",
  10: "hsl(0, 0%, 97%)",
}

export const successColors: ColorsType = {
  1: "hsl(125, 86%, 14%)",
  2: "hsl(125, 73%, 20%)",
  3: "hsl(125, 56%, 29%)",
  4: "hsl(122, 47%, 35%)",
  5: "hsl(122, 39%, 41%)",
  6: "hsl(123, 35%, 51%)",
  7: "hsl(123, 38%, 63%)",
  8: "hsl(122, 42%, 75%)",
  9: "hsl(126, 49%, 84%)",
  10: "hsl(125, 65%, 93%)",
}

export const mainColors: ColorsType = {
  1: "#EBF8FF",
  2: "#BEE3F8",
  3: "#90CDF4",
  4: "#63B3ED",
  5: "#4299E1",
  6: "#3182CE",
  7: "#2B6CB0",
  8: "#2C5282",
  9: "#2A4365",
}

export const alternateColors: ColorsType = {
  1: "#FFFAF0",
  2: "#FEEBC8",
  3: "#FBD38D",
  4: "#F6AD55",
  5: "#ED8936",
  6: "#DD6B20",
  7: "#C05621",
  8: "#9C4221",
  9: "#7B341E",
}

export const Colors = {
  accents: {
    error: errorColors,
    success: successColors,
  },
  alternate: alternateColors,
  grays: {
    cool: coolGrayColors,
    neutral: neutralGrayColors,
  },
  main: mainColors,
  tw: defaultConfig.theme.colors,
  oc: {
    white: "#ffffff",
    black: "#000000",
    gray: [
      "#f8f9fa",
      "#f1f3f5",
      "#e9ecef",
      "#dee2e6",
      "#ced4da",
      "#adb5bd",
      "#868e96",
      "#495057",
      "#343a40",
      "#212529",
    ],
    red: [
      "#fff5f5",
      "#ffe3e3",
      "#ffc9c9",
      "#ffa8a8",
      "#ff8787",
      "#ff6b6b",
      "#fa5252",
      "#f03e3e",
      "#e03131",
      "#c92a2a",
    ],
    pink: [
      "#fff0f6",
      "#ffdeeb",
      "#fcc2d7",
      "#faa2c1",
      "#f783ac",
      "#f06595",
      "#e64980",
      "#d6336c",
      "#c2255c",
      "#a61e4d",
    ],
    grape: [
      "#f8f0fc",
      "#f3d9fa",
      "#eebefa",
      "#e599f7",
      "#da77f2",
      "#cc5de8",
      "#be4bdb",
      "#ae3ec9",
      "#9c36b5",
      "#862e9c",
    ],
    violet: [
      "#f3f0ff",
      "#e5dbff",
      "#d0bfff",
      "#b197fc",
      "#9775fa",
      "#845ef7",
      "#7950f2",
      "#7048e8",
      "#6741d9",
      "#5f3dc4",
    ],
    indigo: [
      "#edf2ff",
      "#dbe4ff",
      "#bac8ff",
      "#91a7ff",
      "#748ffc",
      "#5c7cfa",
      "#4c6ef5",
      "#4263eb",
      "#3b5bdb",
      "#364fc7",
    ],
    blue: [
      "#e7f5ff",
      "#d0ebff",
      "#a5d8ff",
      "#74c0fc",
      "#4dabf7",
      "#339af0",
      "#228be6",
      "#1c7ed6",
      "#1971c2",
      "#1864ab",
    ],
    cyan: [
      "#e3fafc",
      "#c5f6fa",
      "#99e9f2",
      "#66d9e8",
      "#3bc9db",
      "#22b8cf",
      "#15aabf",
      "#1098ad",
      "#0c8599",
      "#0b7285",
    ],
    teal: [
      "#e6fcf5",
      "#c3fae8",
      "#96f2d7",
      "#63e6be",
      "#38d9a9",
      "#20c997",
      "#12b886",
      "#0ca678",
      "#099268",
      "#087f5b",
    ],
    green: [
      "#ebfbee",
      "#d3f9d8",
      "#b2f2bb",
      "#8ce99a",
      "#69db7c",
      "#51cf66",
      "#40c057",
      "#37b24d",
      "#2f9e44",
      "#2b8a3e",
    ],
    lime: [
      "#f4fce3",
      "#e9fac8",
      "#d8f5a2",
      "#c0eb75",
      "#a9e34b",
      "#94d82d",
      "#82c91e",
      "#74b816",
      "#66a80f",
      "#5c940d",
    ],
    yellow: [
      "#fff9db",
      "#fff3bf",
      "#ffec99",
      "#ffe066",
      "#ffd43b",
      "#fcc419",
      "#fab005",
      "#f59f00",
      "#f08c00",
      "#e67700",
    ],
    orange: [
      "#fff4e6",
      "#ffe8cc",
      "#ffd8a8",
      "#ffc078",
      "#ffa94d",
      "#ff922b",
      "#fd7e14",
      "#f76707",
      "#e8590c",
      "#d9480f",
    ],
  },

  cta: {
    "400": "#F58C8C",
    "500": "#FC737A",
    "600": "#F85961",
    "700": "#E73E47",
    "800": "#67181C",
    "900": "#251111",
  },
}
