import * as React from "react"

import { Box, BoxProps } from "../"

export const Container: React.FC<BoxProps> = props => (
  <Box
    maxWidth="m"
    pl={[5, 5, 6]}
    pr={[5, 5, 6]}
    mx="auto"
    width={1}
    {...props}
  />
)
