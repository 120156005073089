import * as React from "react"
import { ContrastScore } from "../../../backend/functions/contrast-check/interfaces"
import { Colors } from "../../materials"
import { TextBold, Label, Box } from "../../elements"
import { IconText, IconTextLarge } from "../../components"

interface Props {
  color: string
  backgroundColor?: string
  contrastScore: ContrastScore
  onDark?: boolean
  isLarge?: boolean
}

export const IconTextBackgroundColor: React.FC<Props> = ({
  color,
  backgroundColor,
  contrastScore,
  onDark = false,
  isLarge = false,
}) => (
  <>
    <Box position="relative">
      <Box>
        {isLarge ? (
          <IconTextLarge
            bg={backgroundColor}
            color={color}
            mx={"auto"}
            display="flex"
          />
        ) : (
          <IconText
            bg={backgroundColor}
            color={color}
            mx={"auto"}
            display="flex"
          />
        )}
      </Box>

      <Box mt={3} />
      <Box as="p" flex="1 1 auto" display="flex" flexDirection="column">
        <Label
          as="span"
          css={{ textAlign: "center" }}
          color={onDark ? Colors.oc.gray[4] : undefined}
        >
          {Number(contrastScore.contrast).toLocaleString("en", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </Label>
        <TextBold
          as="span"
          color={
            contrastScore.passesAAA || contrastScore.passesAA
              ? Colors.tw.green["700"]
              : Colors.tw.red["700"]
          }
          css={{ textAlign: "center" }}
          fontSize={4}
        >
          {contrastScore.passesAAA
            ? "AAA"
            : contrastScore.passesAA
            ? "AA"
            : "FAIL"}
        </TextBold>
      </Box>
    </Box>
  </>
)
