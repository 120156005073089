import React from "react"

const SvgLock1 = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <rect x={3.75} y={9.75} width={16.5} height={13.5} rx={1.5} ry={1.5} />
    <path d="M6.75 9.75V6a5.25 5.25 0 0110.5 0v3.75" />
    <path d="M12 15v3" />
  </svg>
)

export default SvgLock1
