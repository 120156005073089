import * as React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import styled, { Interpolation, FunctionInterpolation } from "@emotion/styled"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { Colors } from "../../materials/Colors"
import { Fonts, FontSizes, Breakpoints } from "../../materials"

interface Props {
  theme?: ButtonTheme
  size?: ButtonSize
}

export enum ButtonTheme {
  MAIN,
  NEUTRAL,
  CTA,
}
export enum ButtonSize {
  LARGE,
  MEDIUM,
  SMALL,
  TINY,
}

const STYLES_BY_THEME: Record<ButtonTheme, Interpolation<Props>> = {
  [ButtonTheme.MAIN]: {
    background: Colors.oc.blue[9],
    color: Colors.oc.white,
    "&:hover:not(:focus)": {
      background: Colors.oc.blue[8],
    },
  },
  [ButtonTheme.NEUTRAL]: {
    background: Colors.oc.gray[4],
    color: Colors.oc.gray[8],
    "&:hover:not(:focus)": {
      background: Colors.oc.gray[5],
    },
  },
  [ButtonTheme.CTA]: {
    background: Colors.tw.pink["600"],
    color: Colors.oc.gray[9],
    "&:hover:not(:focus)": {
      background: Colors.tw.pink["500"],
    },
  },
}

const STYLES_BY_SIZE: Record<ButtonSize, Interpolation<Props>> = {
  [ButtonSize.LARGE]: {
    padding: ".5em 1em",
    fontSize: `${FontSizes[4]}px`,
  },
  [ButtonSize.MEDIUM]: {
    padding: ".5em 1em",
    fontSize: `${FontSizes[2]}px`,
    [`@media (min-width: ${Breakpoints[1]})`]: {
      fontSize: `${FontSizes[3]}px`,
    },
  },
  [ButtonSize.SMALL]: {
    padding: ".4em .8em",
    fontSize: `${FontSizes[1]}px`,
  },
  [ButtonSize.TINY]: {
    padding: ".5em 1em",
    fontSize: `${FontSizes[1]}px`,
  },
}

const styles: FunctionInterpolation<Props> = ({
  size = ButtonSize.MEDIUM,
  theme = ButtonTheme.MAIN,
}) => ({
  // override button
  border: "none",
  fontSize: "inherit",
  appearance: "none",

  // override link
  textDecoration: "none",

  // styles
  borderRadius: 4,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  // wcag 2.5.5: Target Size
  // https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  minHeight: 44,
  lineHeight: 1.3,
  userSelect: "none",
  transition: "background-color 200ms ease-in, color 200ms ease-in",
  textAlign: "center",

  fontWeight: Fonts.sansBold.weight,
  fontFamily: Fonts.sansBold.family,

  "&:focus:not(.focus-visible)": {
    outline: "none",
  },
  "&.focus-visible": {
    outline: `2px solid ${Colors.oc.blue[9]}`,
    outlineOffset: 4,
  },

  "&:active": {
    transform: "translateY(1px)",
  },
  "&:disabled": {
    opacity: 0.8,
  },
  "&:not(:disabled)": {
    cursor: "pointer",
  },
  ...STYLES_BY_THEME[theme],
  ...STYLES_BY_SIZE[size],
})

// @ts-ignore
const ElButtonGatsby = styled(props => <GatsbyLink {...props} />)<Props>(styles)
// @ts-ignore
const ElButtonAnchor = styled(props => <OutboundLink {...props} />)<Props>(
  styles
)
// @ts-ignore
const ElButtonButton = styled.button<Props>(styles)

export const ButtonGatsby: React.FC<Props & GatsbyLinkProps<{}>> = ({
  size = ButtonSize.MEDIUM,
  theme = ButtonTheme.MAIN,
  ...rest
}) => <ElButtonGatsby theme={theme} size={size} {...rest} />

export const ButtonAnchor: React.FC<
  Props & React.PropsWithRef<JSX.IntrinsicElements["a"]>
> = ({ size = ButtonSize.MEDIUM, theme = ButtonTheme.MAIN, ...rest }) => (
  <ElButtonAnchor theme={theme} size={size} {...rest} />
)

export const Button: React.FC<
  Props & React.PropsWithRef<JSX.IntrinsicElements["button"]>
> = React.forwardRef(
  ({ size = ButtonSize.MEDIUM, theme = ButtonTheme.MAIN, ...rest }, ref) => (
    <ElButtonButton theme={theme} size={size} ref={ref} {...rest} />
  )
)
