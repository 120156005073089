import * as React from "react"
import {
  ButtonContrastScore,
  ButtonState,
} from "../../../backend/functions/contrast-check/interfaces"
import * as deepMerge from "deepmerge"
import {
  Box,
  ButtonIcon,
  Heading,
  SubHeading,
  Button,
  LinkButton,
  LinkArrowButton,
  Container,
  Text,
  Label,
  TextBold,
  LinkAnchor,
} from "../../elements"
import {
  IconTextBackgroundColor,
  IconObjectBackgroundColor,
  IconText,
  IconContrast,
} from "../../components"
import { Icons, Colors } from "../../materials"
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog"
import { CHECKER_BG_PROPS } from "../../partials/button-contrast-check/checker-background"

type Props = {
  state: "DEFAULT" | "HOVER" | "FOCUS"
  contrast: ButtonContrastScore
  showWarning: boolean
  extraInfo?: () => React.ReactNode
  buttonData: {
    normal: ButtonState
    hover?: ButtonState
    focus?: ButtonState
    tagName: string
    showCheckerBackground: boolean
  }
}

const TEXT_BY_CONTRASTING_PROPERTY = {
  background: "background",
  border: "border",
  boxShadow: "box-shadow",
  outline: "outline",
}

export const InfoTextContrast: React.FC<Props> = ({
  contrast,
  showWarning = false,
  state = "DEFAULT",
  extraInfo,
  buttonData,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <>
      {/* COMPENSATE padding right */}
      <Box fontSize={1} ml={"1.1em"}>
        <LinkArrowButton onClick={() => setIsOpen(true)}>
          See Details
        </LinkArrowButton>
      </Box>
      {isOpen ? (
        <Dialog onDismiss={() => setIsOpen(false)}>
          <Box
            m="5vh auto"
            maxWidth={[300, 400, 450, 450]}
            justifyContent="center"
            css={{
              overflowY: "auto",
              WebkitOverflowScrolling: "touch",
              maxHeight: "95vh",
            }}
          >
            <Box bg={Colors.oc.white} css={{ position: "relative" }}>
              <Box
                css={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                pt={3}
                pr={3}
              >
                <ButtonIcon aria-label="Close" onClick={() => setIsOpen(false)}>
                  <Icons.SvgClose />
                </ButtonIcon>
              </Box>
              <Container py={6}>
                <SubHeading textAlign="center">{state} Style</SubHeading>
                {extraInfo ? (
                  <Text
                    fontSize={2}
                    textAlign="center"
                    color={Colors.oc.blue[9]}
                  >
                    {extraInfo()}
                  </Text>
                ) : null}
                <Box mt={2} />

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  ml={[-5, -5, -6]}
                  mr={[-5, -5, -6]}
                  px={2}
                  py={6}
                  css={{
                    ...(buttonData.showCheckerBackground
                      ? buttonData.normal.data.wrapperHasBgImage
                        ? { background: buttonData.normal.data.wrapperBgStyle }
                        : CHECKER_BG_PROPS
                      : {
                          background: buttonData.normal.data.wrapperHasBgImage
                            ? buttonData.normal.data.wrapperBgStyle
                            : buttonData.normal.data.extrapolatedColors
                                .wrapperBackgrounds.length === 1
                            ? buttonData.normal.data.extrapolatedColors
                                .wrapperBackgrounds[0]
                            : buttonData.normal.data.wrapperBgStyle,
                        }),
                    overflow: "hidden",
                  }}
                >
                  <Box
                    as={buttonData.tagName}
                    onClick={event => event.preventDefault()}
                    alignSelf="center"
                    css={deepMerge(
                      buttonData.normal.data.style.computedStyle,
                      state === "HOVER"
                        ? buttonData.hover &&
                          buttonData.hover.data &&
                          buttonData.hover.data.style &&
                          buttonData.hover.data.style.computedStyle
                          ? buttonData.hover.data.style.computedStyle
                          : {}
                        : state === "FOCUS"
                        ? buttonData.focus &&
                          buttonData.focus.data &&
                          buttonData.focus.data.style &&
                          buttonData.focus.data.style.computedStyle
                          ? {
                              ...buttonData.focus.data.style.computedStyle,
                              ...(buttonData.focus.data.hasCustomFocus
                                ? {}
                                : {
                                    outline:
                                      "rgba(0,103,244,0.247) auto 5px !important",
                                  }),
                            }
                          : {}
                        : {}
                    )}
                    dangerouslySetInnerHTML={{
                      __html: buttonData.normal.data.html,
                    }}
                  />
                </Box>
                <Box mt={4} />

                <TextBold fontSize={2}>Text Contrast</TextBold>
                <Box>
                  {contrast.inner.map((inner, index) => (
                    <Box mt={4} display="flex" key={index} alignItems="center">
                      <Box flex="1 1 auto">
                        <Box display={["block", "none", "none"]}>
                          <Text fontSize={1}>background</Text>
                          <Box display="flex" alignItems="center">
                            <Box
                              border={`1px solid ${Colors.oc.gray[6]}`}
                              bg={inner.color1}
                              width={20}
                              height={20}
                              borderRadius={5}
                            />
                            <Box ml={2} />
                            <Label>{inner.color1}</Label>
                          </Box>
                        </Box>
                        <Box display={["none", "flex"]} alignItems="center">
                          <Box width={140} mr={2}>
                            <Text fontSize={1}>background</Text>
                          </Box>

                          <Box
                            border={`1px solid ${Colors.oc.gray[6]}`}
                            bg={inner.color1}
                            width={20}
                            height={20}
                            borderRadius={5}
                          />
                          <Box ml={2} />
                          <Label>{inner.color1}</Label>
                        </Box>
                        <Box mt={1} />
                        <Box display={["block", "none", "none"]}>
                          <Text fontSize={1}>text color</Text>
                          <Box display="flex" alignItems="center">
                            <Box
                              border={`1px solid ${Colors.oc.gray[6]}`}
                              bg={inner.color2}
                              width={20}
                              height={20}
                              borderRadius={5}
                            />
                            <Box ml={2} />
                            <Label>{inner.color2}</Label>
                          </Box>
                        </Box>
                        <Box
                          display={["none", "flex"]}
                          flexWrap="wrap"
                          alignItems="center"
                        >
                          <Box width={140} mr={2}>
                            <Text fontSize={1}>text color</Text>
                          </Box>
                          <Box
                            border={`1px solid ${Colors.oc.gray[6]}`}
                            bg={inner.color2}
                            width={20}
                            height={20}
                            borderRadius={5}
                          />
                          <Box ml={2} />
                          <Label>{inner.color2}</Label>
                        </Box>
                      </Box>
                      <Box ml={6} />
                      <Box
                        flex="0 0 auto"
                        display="flex"
                        flexDirection={["column", "row"]}
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <IconText bg={inner.color1} color={inner.color2} />
                        </Box>
                        <Box textAlign="center" pl={[0, 3]} mt={[2, 0]}>
                          <Label>
                            {Number(inner.contrast).toLocaleString("en", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                          </Label>
                          <TextBold
                            color={
                              inner.passesAAA || inner.passesAA
                                ? Colors.tw.green["700"]
                                : Colors.tw.red["700"]
                            }
                            css={{ textAlign: "center" }}
                            mt={-1}
                            fontSize={4}
                          >
                            {inner.passesAAA
                              ? "AAA"
                              : inner.passesAA
                              ? "AA"
                              : "FAIL"}
                          </TextBold>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>

                {contrast.outer && contrast.outer.length > 0 ? (
                  <>
                    <Box mt={7} />
                    <TextBold fontSize={2}>Non-Text Contrast</TextBold>
                    <Box>
                      {contrast.outer.map((outer, index) => (
                        <Box
                          mt={4}
                          display="flex"
                          key={index}
                          alignItems="center"
                        >
                          <Box flex="1 1 auto">
                            <Box display={["block", "none", "none"]}>
                              <Text fontSize={1}>adjacent background</Text>
                              <Box display="flex" alignItems="center">
                                <Box
                                  border={`1px solid ${Colors.oc.gray[6]}`}
                                  bg={outer.color1}
                                  width={20}
                                  height={20}
                                  borderRadius={5}
                                />
                                <Box ml={2} />
                                <Label>{outer.color1}</Label>
                              </Box>
                            </Box>
                            <Box display={["none", "flex"]} alignItems="center">
                              <Box width={140} mr={2}>
                                <Text fontSize={1}>adjacent background</Text>
                              </Box>

                              <Box
                                border={`1px solid ${Colors.oc.gray[6]}`}
                                bg={outer.color1}
                                width={20}
                                height={20}
                                borderRadius={5}
                              />
                              <Box ml={2} />
                              <Label>{outer.color1}</Label>
                            </Box>
                            <Box mt={1} />
                            <Box display={["block", "none", "none"]}>
                              <Text fontSize={1}>
                                {outer.contrastWith
                                  ? TEXT_BY_CONTRASTING_PROPERTY[
                                      outer.contrastWith
                                    ] || outer.contrastWith
                                  : "boundary"}
                              </Text>
                              <Box display="flex" alignItems="center">
                                <Box
                                  border={`1px solid ${Colors.oc.gray[6]}`}
                                  bg={outer.color2}
                                  width={20}
                                  height={20}
                                  borderRadius={5}
                                />
                                <Box ml={2} />
                                <Label>{outer.color2}</Label>
                              </Box>
                            </Box>
                            <Box display={["none", "flex"]} alignItems="center">
                              <Box width={140} mr={2}>
                                <Text fontSize={1}>
                                  {outer.contrastWith
                                    ? TEXT_BY_CONTRASTING_PROPERTY[
                                        outer.contrastWith
                                      ] || outer.contrastWith
                                    : "boundary"}
                                </Text>
                              </Box>
                              <Box
                                border={`1px solid ${Colors.oc.gray[6]}`}
                                bg={outer.color2}
                                width={20}
                                height={20}
                                borderRadius={5}
                              />
                              <Box ml={2} />
                              <Label>{outer.color2}</Label>
                            </Box>
                          </Box>
                          <Box ml={6} />
                          <Box
                            flex="0 0 auto"
                            display="flex"
                            flexDirection={["column", "row"]}
                            alignItems="center"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <IconContrast
                                object={outer.color2}
                                bg={outer.color1}
                              />
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              textAlign="center"
                              pl={[0, 3]}
                              mt={[2, 0]}
                            >
                              <Label>
                                {Number(outer.contrast).toLocaleString("en", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                              </Label>
                              <TextBold
                                color={
                                  showWarning
                                    ? Colors.oc.gray[7]
                                    : outer.passesAA
                                    ? Colors.tw.green["700"]
                                    : Colors.tw.red["700"]
                                }
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}
                                mt={-1}
                                fontSize={4}
                              >
                                {showWarning ? (
                                  <Box
                                    width={20}
                                    display="flex"
                                    alignItems="flex-end"
                                    css={{
                                      marginTop: 3,
                                      svg: {
                                        width: "100%",
                                        height: "auto",
                                        "path:nth-child(1)": {
                                          fill: Colors.tw.yellow["300"],
                                        },
                                        "path:nth-child(2)": {},
                                        "path:nth-child(3)": {},
                                      },
                                    }}
                                  >
                                    <Icons.SvgWarning />
                                  </Box>
                                ) : outer.passesAA ? (
                                  "AA"
                                ) : (
                                  "FAIL"
                                )}
                              </TextBold>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    {showWarning ? (
                      <Box
                        mt={4}
                        maxWidth="copy"
                        mx="auto"
                        display="flex"
                        justifyContent="center"
                      >
                        <Text fontSize={2} color={Colors.oc.blue[9]}>
                          <Box
                            width={20}
                            mr={2}
                            color={Colors.oc.gray[7]}
                            display="inline-flex"
                            alignItems="flex-end"
                            css={{
                              marginTop: 3,
                              svg: {
                                width: "100%",
                                height: "auto",
                                "path:nth-child(1)": {
                                  fill: Colors.tw.yellow["300"],
                                },
                                "path:nth-child(2)": {},
                                "path:nth-child(3)": {},
                              },
                            }}
                          >
                            <Icons.SvgWarning />
                          </Box>
                          Because the focus style of this button is not
                          customized, this button{" "}
                          <LinkAnchor
                            css={{ display: "inline" }}
                            href="https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html#active-user-interface-component-examples"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            technically passes
                          </LinkAnchor>
                          . However, as the contrast is not sufficient, it's
                          recommended to{" "}
                          <LinkAnchor
                            href="https://www.w3.org/WAI/WCAG21/Techniques/general/G195"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            override the <em>default focus style</em>
                          </LinkAnchor>
                          .
                        </Text>
                      </Box>
                    ) : null}
                  </>
                ) : null}
              </Container>
            </Box>
          </Box>
        </Dialog>
      ) : null}
    </>
  )
}
