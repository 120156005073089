import * as React from "react"
import { Colors, Fonts, FontSizes } from "../../materials"
import { BarStackHorizontal } from "@vx/shape"
import { Group } from "@vx/group"
import { AxisBottom, AxisLeft } from "@vx/axis"
import { scaleBand, scaleLinear, scaleOrdinal } from "@vx/scale"
import { LegendOrdinal } from "@vx/legend"
import { ParentSize } from "@vx/responsive"

const failColor = Colors.oc.red[5]
const AAColor = Colors.oc.green[3]
const AAAColor = Colors.oc.green[5]

export const ChartBarGroupHorizontal: React.FC<{
  margin?: {
    top: number
    left: number
    right: number
    bottom: number
  }
  data: Record<string, string | number>[]
  keys: string[]
  yAccessor?: (
    value: Record<string, string | number>,
    index: number,
    array: Record<string, string | number>[]
  ) => unknown
  hasLegend?: boolean
}> = ({
  margin = {
    top: 30,
    left: 60,
    right: 0,
    bottom: 30,
  },
  data,
  keys,
  yAccessor = d => d.label,
  hasLegend = true,
}) => {
  return (
    <ParentSize className="graph-container">
      {({ width, height }) => {
        const totals = data.reduce((ret, cur) => {
          const t = keys.reduce((dailyTotal, k) => {
            dailyTotal += +cur[k]
            return dailyTotal
          }, 0)
          ret.push(t)
          return ret
        }, [])

        // accessors
        const y = yAccessor
        const x = d => d.value

        // scales
        // scales
        const xScale = scaleLinear({
          domain: [0, Math.max(...totals)],
          nice: true,
        })
        const yScale = scaleBand({
          domain: data.map(y),
          padding: 0.5,
        })
        const color = scaleOrdinal({
          domain: keys,
          range: [failColor, AAColor, AAAColor],
        })

        // bounds
        // bounds
        const xMax = width - margin.left - margin.right
        const yMax = height - margin.top - margin.bottom

        xScale.rangeRound([0, xMax])
        yScale.rangeRound([yMax, 0])
        return (
          <div style={{ position: "relative" }}>
            <svg width={width} height={height} fill="none">
              <rect width={width} height={height} />
              <Group top={margin.top} left={margin.left}>
                <BarStackHorizontal
                  data={data}
                  keys={keys}
                  height={yMax}
                  y={y}
                  xScale={xScale}
                  yScale={yScale}
                  color={color}
                >
                  {barStacks => {
                    return barStacks.map(barStack => {
                      return barStack.bars.map(bar => {
                        return (
                          <rect
                            key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                            x={bar.x}
                            y={bar.y}
                            width={bar.width}
                            height={bar.height}
                            fill={bar.color}
                          />
                        )
                      })
                    })
                  }}
                </BarStackHorizontal>
                <AxisLeft
                  hideAxisLine={true}
                  hideTicks={true}
                  scale={yScale}
                  stroke={Colors.oc.gray[6]}
                  tickStroke={Colors.oc.gray[6]}
                  tickLabelProps={(value, index) => ({
                    fill: Colors.oc.gray[6],
                    fontFamily: Fonts.sansBold.family,
                    fontWeight: Fonts.sansBold.weight,
                    fontSize: FontSizes[2],
                    textAnchor: "end",
                    dy: "0.33em",
                  })}
                />
                <AxisBottom
                  top={yMax}
                  hideTicks={true}
                  scale={xScale}
                  stroke={Colors.oc.gray[6]}
                  tickStroke={Colors.oc.gray[6]}
                  tickLabelProps={(value, index) => ({
                    fill: Colors.oc.gray[6],
                    fontFamily: Fonts.sansBold.family,
                    fontWeight: Fonts.sansBold.weight,
                    fontSize: FontSizes[2],
                    textAnchor: "right",
                  })}
                />
              </Group>
            </svg>
            {hasLegend ? (
              <div
                style={{
                  position: "absolute",
                  top: margin.top / 2 - 10,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: Fonts.sansBold.family,
                  fontWeight: Fonts.sansBold.weight,
                  fontSize: FontSizes[2],
                }}
              >
                <LegendOrdinal
                  scale={color}
                  direction="row"
                  labelMargin="0 15px 0 0"
                />
              </div>
            ) : null}
          </div>
        )
      }}
    </ParentSize>
  )
}
