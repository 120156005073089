import React from "react"

const SvgCheckCircle1 = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <circle fill="currentColor" cx={12} cy={11.999} r={11.25} />
    <path d="M6 13.223L8.45 16.7a1.049 1.049 0 001.707.051L18 6.828" />
  </svg>
)

export default SvgCheckCircle1
