import * as React from "react"
import styled, { FunctionInterpolation } from "@emotion/styled"
import { Colors } from "../../materials"
import { Box, BoxProps } from "../"

export enum FormSingleInputTheme {
  MAIN,
  CTA,
}

interface FormSinpleInputProps {
  theme?: FormSingleInputTheme
  hasError?: boolean
  disabled?: boolean
}

const styles: FunctionInterpolation<FormSinpleInputProps> = props => ({
  maxWidth: "580px",
  width: "100%",
  borderRadius: "4px",
  padding: "0.2em 0.2em",
  background: Colors.oc.white,
  boxShadow: `0px 0px 0px 2px ${Colors.oc.blue[9]}`,
  ".input-wrapper": {
    display: "flex",
  },
  "input, .input-wrapper": {
    flex: "1 1 auto",
    width: "100%",
  },
  ".show-on-focus": {
    visibility: "none",
  },
  ".input-wrapper:focus-within .show-on-focus": {
    display: "block",
  },
  input: {
    background: Colors.oc.white as string,
  },
  button: {
    position: "relative",
  },
  "button[type='submit'].focus-visible": {
    outline: `2px solid ${Colors.tw.yellow["200"]}`,
    outlineOffset: -7,
  },
  "button[type='button'].focus-visible": {
    outline: `2px solid ${Colors.oc.blue[9]}`,
    outlineOffset: -7,
  },
  "input, input:focus": {
    border: 0,
    borderRadius: 0,
    boxShadow: "none",
    outline: "none",
  },
  "&:focus-within": {
    boxShadow:
      props.theme === FormSingleInputTheme.MAIN
        ? `0px 0px 0px 3px ${Colors.oc.blue[9]}`
        : `0px 0px 0px 3px ${Colors.cta["500"]}`,
  },
  "&:focus-within:focus:not(.focus-visible)": {
    boxShadow: "none",
  },
  ...(props.hasError
    ? {
        boxShadow: `0px 0px 0px 2px ${Colors.cta["500"]}`,
      }
    : {}),
  ...(props.disabled
    ? {
        background: Colors.grays.cool[9],
        "input[disabled]": {
          boxShadow: "none",
        },
      }
    : {}),
})

// @ts-ignore
export const FormSingleInput = styled(props => <Box as="form" {...props} />)<
  BoxProps & FormSinpleInputProps
>(styles)
