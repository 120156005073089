import * as React from "react"
import { Box } from "../Box"
import { Colors } from "../../materials"
import ContentLoader from "react-content-loader"

export enum DashboardCardTheme {
  Success = "Success",
  Fail = "Fail",
  Loading = "Loading",
}

const GRADIENT_BY_THEME: Record<DashboardCardTheme, string> = {
  [DashboardCardTheme.Success]: `linear-gradient(to right, ${Colors.tw.green["200"]}, ${Colors.tw.green["100"]} 50%, ${Colors.oc.white})`,
  [DashboardCardTheme.Fail]: `linear-gradient(to right, ${Colors.tw.red["200"]}, ${Colors.tw.red["100"]} 50%, ${Colors.oc.white})`,
  [DashboardCardTheme.Loading]: `linear-gradient(to right, ${Colors.oc.gray[2]}, ${Colors.oc.gray[1]} 50%, ${Colors.oc.white})`,
}

const COLOR_BY_THEME: Record<DashboardCardTheme, string> = {
  [DashboardCardTheme.Success]: Colors.tw.green["500"],
  [DashboardCardTheme.Fail]: Colors.tw.red["500"],
  [DashboardCardTheme.Loading]: Colors.oc.gray[6],
}

export const DashboardCard: React.FC<
  {
    theme: DashboardCardTheme
  } & React.ComponentProps<typeof Box>
> = ({ theme = DashboardCardTheme.Success, ...rest }) => {
  if (theme === DashboardCardTheme.Loading) {
    return (
      <ContentLoader
        speed={2}
        width={800}
        height={300}
        primaryColor={Colors.oc.gray[2]}
        secondaryColor={Colors.oc.gray[0]}
        {...rest}
      >
        <rect x="0" y="0" rx="5" ry="5" width={800} height={300} />
      </ContentLoader>
    )
  }

  return (
    <Box
      p={4}
      width={1}
      display="flex"
      flexDirection="column"
      css={{
        borderRadius: 10,
        border: `1px solid ${Colors.oc.gray[2]}`,
        backgroundImage: GRADIENT_BY_THEME[theme],
        position: "relative",
        overflow: "hidden",
        "&:before": {
          content: `''`,
          position: "absolute",
          width: "4px",
          top: 0,
          left: 0,
          bottom: 0,
          background: COLOR_BY_THEME[theme],
        },
      }}
      {...rest}
    />
  )
}
