import * as React from "react"
import { Colors } from "../../materials"
import {
  Box,
  LinkAnchor,
  Code,
  ButtonIcon,
  Input,
  List,
  ListItem,
} from "../../elements"

const AccordionBox = props => (
  <Box
    {...props}
    css={{
      ".accordion": {
        // background: 'red'
        width: "100%",
      },
      ".accordion button": {
        background: "transparent",
        border: 0,
        borderRadius: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 8,
        paddingRight: 8,
        minHeight: 44,
        width: "100%",
        fontWeight: 900,
        fontSize: 20,
        color: Colors.oc.blue[9],
        cursor: "pointer",
        borderTop: `1px solid ${Colors.oc.gray[6]}`,
      },
      ".accordion button:focus": {
        outline: `4px solid ${Colors.oc.blue[9]}`,
        outlineOffset: 5,
      },
      '.accordion button[aria-expanded="true"]': {
        borderBottom: `1px solid ${Colors.oc.gray[4]}`,
      },
      '.accordion h3:last-of-type button[aria-expanded="false"]': {
        borderBottom: `1px solid ${Colors.oc.gray[6]}`,
      },
      ".accordion section:last-of-type": {
        borderBottom: `1px solid ${Colors.oc.gray[6]}`,
      },
      ".accordion section": {
        paddingLeft: 8,
        paddingRight: 9,
        paddingTop: 16,
        paddingBottom: 16,
        fontSize: 16,
      },
      ".accordion svg": {
        width: 24,
        height: 24,
        color: "inherit",
      },
    }}
  />
)

enum States {
  CLOSE_CLOSE,
  OPEN_CLOSE,
  CLOSE_OPEN,
  OPEN_OPEN,
}

export const SnippetAccordion: React.FC<{}> = () => {
  const [state, setState] = React.useState<keyof States>(States.OPEN_CLOSE)

  const onItem1Clicked = () => {
    switch (state) {
      case States.CLOSE_CLOSE:
        setState(States.OPEN_CLOSE)
        break
      case States.OPEN_CLOSE:
        setState(States.CLOSE_CLOSE)
        break
      case States.CLOSE_OPEN:
        setState(States.OPEN_OPEN)
        break
      case States.OPEN_OPEN:
        setState(States.CLOSE_OPEN)
        break
      default:
        setState(States.OPEN_CLOSE)
        break
    }
  }
  const onItem2Clicked = () => {
    switch (state) {
      case States.CLOSE_CLOSE:
        setState(States.CLOSE_OPEN)
        break
      case States.OPEN_CLOSE:
        setState(States.OPEN_OPEN)
        break
      case States.CLOSE_OPEN:
        setState(States.CLOSE_CLOSE)
        break
      case States.OPEN_OPEN:
        setState(States.OPEN_CLOSE)
        break
      default:
        setState(States.OPEN_CLOSE)
        break
    }
  }

  const SNIPPED_BY_STATE: Record<States, string> = {
    [States.CLOSE_CLOSE]: `<div>
    <h3>
      <button aria-expanded="false"
              aria-controls="accordion-panel-1"
              id="accordion-header-1">
        Item 1
        <svg aria-hidden="true">
          ...
        </svg>
      </button>
    </h3>
    <section id="accordion-panel-1"
             aria-labelledby="accordion-header-1"
             hidden>
      <p>Lorem ipsum dolor sit amet ...</p>
    </section>
    <h3>
      <button aria-expanded="false"
              aria-controls="accordion-panel-2"
              id="accordion-header-2"
      >
        Item 2
        <svg aria-hidden="true">...</svg>
      </button>
    </h3>
    <section id="accordion-panel-2"
             aria-labelledby="accordion-header-2"
             hidden>
      <p>Lorem ipsum dolor sit amet...</p>
    </section>
  </div>`,
    [States.OPEN_CLOSE]: `<div>
    <h3>
      <button id="accordion-header-1"
              aria-expanded="true"
              aria-controls="accordion-panel-1">
        Item 1
        <svg aria-hidden="true">...</svg>
      </button>
    </h3>
    <section id="accordion-panel-1"
             aria-labelledby="accordion-header-1">
      <p>Lorem ipsum dolor sit amet...</p>
    </section>
    <h3>
      <button id="accordion-header-2"
              aria-expanded="false"
              aria-controls="accordion-panel-2">
        Item 2
        <svg aria-hidden="true">...</svg>
      </button>
    </h3>
    <section id="accordion-panel-2"
             aria-labelledby="accordion-header-2"
             hidden>
      <p>Lorem ipsum dolor sit amet...</p>
      
    </section>
  </div>`,
    [States.CLOSE_OPEN]: `<div>
    <h3>
      <button id="accordion-header-1"
              aria-expanded="false"
              aria-controls="accordion-panel-1">
        Item 1
        <svg aria-hidden="true">...</svg>
      </button>
    </h3>
    <section id="accordion-panel-1"
             aria-labelledby="accordion-header-1"
             hidden>
      <p>Lorem ipsum dolor sit amet...</p>
    </section>
    <h3>
      <button id="accordion-header-2"
              aria-expanded="true"
              aria-controls="accordion-panel-2">
        Item 2
        <svg aria-hidden="true">...</svg>
      </button>
    </h3>
    <section id="accordion-panel-2"
             aria-labelledby="accordion-header-2">
      <p>Lorem ipsum dolor sit amet...</p>
    </section>
  </div>`,
    [States.OPEN_OPEN]: `<div>
    <h3>
      <button id="accordion-header-1"
              aria-expanded="true"
              aria-controls="accordion-panel-1">
        Item 1
        <svg aria-hidden="true">...</svg>
      </button>
    </h3>
    <section id="accordion-panel-1"
             aria-labelledby="accordion-header-1">
      <p>Lorem ipsum dolor sit amet...</p>
    </section>
    <h3>
      <button id="accordion-header-2"
              aria-expanded="true"
              aria-controls="accordion-panel-2">
        Item 2
        <svg aria-hidden="true">...</svg>
      </button>
    </h3>
    <section id="accordion-panel-2"
             aria-labelledby="accordion-header-2">
      <p>Lorem ipsum dolor sit amet</p>
      ...
    </section>
  </div>`,
  }
  return (
    <Box display="flex" flexWrap="wrap" bg={Colors.oc.gray[1]}>
      <AccordionBox
        width={[1, 1, 0.4]}
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        p={4}
      >
        <div className="accordion">
          <h3>
            <button
              aria-expanded={
                state === States.OPEN_CLOSE || state === States.OPEN_OPEN
              }
              aria-controls="accordion-panel-1"
              id="accordion-header-1"
              onClick={onItem1Clicked}
            >
              Item 1
              <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                {state === States.OPEN_OPEN || state === States.OPEN_CLOSE ? (
                  <path d="M19 13H5v-2h14v2z" fill="currentColor" />
                ) : (
                  <path
                    d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
                    fill="currentColor"
                  />
                )}

                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </button>
          </h3>
          <section
            id="accordion-panel-1"
            aria-labelledby="accordion-header-1"
            hidden={state === States.CLOSE_CLOSE || state === States.CLOSE_OPEN}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
            auctor luctus diam, ut lobortis ante pharetra ac. In nec mi odio.
            Donec scelerisque velit vel iaculis tempor. Fusce vehicula, neque
            mollis commodo iaculis, risus justo euismod neque, nec consectetur
            nulla mauris sit amet quam. Vivamus auctor erat in magna euismod
            aliquam. Ut eros est, posuere sit amet sollicitudin a, vulputate
            quis metus. Cras mauris nibh, tempor a consequat nec, sagittis quis
            velit.
          </section>
          <h3>
            <button
              aria-expanded={
                state === States.CLOSE_OPEN || state === States.OPEN_OPEN
              }
              aria-controls="accordion-panel-2"
              id="accordion-header-2"
              onClick={onItem2Clicked}
            >
              Item 2
              <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                {state === States.OPEN_OPEN || state === States.CLOSE_OPEN ? (
                  <path d="M19 13H5v-2h14v2z" fill="currentColor" />
                ) : (
                  <path
                    d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
                    fill="currentColor"
                  />
                )}
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </button>
          </h3>
          <section
            id="accordion-panel-2"
            aria-labelledby="accordion-header-1"
            hidden={state === States.CLOSE_CLOSE || state === States.OPEN_CLOSE}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
            auctor luctus diam, ut lobortis ante pharetra ac. In nec mi odio.
            Donec scelerisque velit vel iaculis tempor. Fusce vehicula, neque
            mollis commodo iaculis, risus justo euismod neque, nec consectetur
            nulla mauris sit amet quam. Vivamus auctor erat in magna euismod
            aliquam. Ut eros est, posuere sit amet sollicitudin a, vulputate
            quis metus. Cras mauris nibh, tempor a consequat nec, sagittis quis
            velit.
          </section>
        </div>
      </AccordionBox>
      <Box width={[1, 1, 0.6]}>
        <Code codeString={SNIPPED_BY_STATE[state]} language="html" />
      </Box>
    </Box>
  )
}
