import * as React from "react"

import { Box, TextBold, BoxProps } from "../elements"

export const IconTextSmall: React.FC<
  { bg?: string; color: string } & BoxProps
> = ({ bg = "transparent", color, ...rest }) => (
  <Box
    as="span"
    display="flex"
    role="img"
    aria-label="Text Contrast"
    css={{
      borderRadius: "50%",
      border: `2px solid currentColor`,
      alignItems: "center",
      justifyContent: "center",
      color,
      backgroundColor: bg,
      width: 20,
      height: 20,
    }}
    {...rest}
  >
    <TextBold as="span" textAlign="center" fontSize={[1, 3]} aria-hidden="true">
      T
    </TextBold>
  </Box>
)

export const IconText: React.FC<{ bg?: string; color: string } & BoxProps> = ({
  bg = "transparent",
  color,
  ...rest
}) => (
  <Box
    as="span"
    display="flex"
    role="img"
    aria-label="Text Contrast"
    css={{
      borderRadius: "50%",
      border: `2px solid currentColor`,

      alignItems: "center",
      justifyContent: "center",
      color,
      backgroundColor: bg,
      width: 30,
      height: 30,
    }}
    {...rest}
  >
    <TextBold as="span" textAlign="center" aria-hidden="true">
      T
    </TextBold>
  </Box>
)

export const IconTextLarge: React.FC<
  { bg?: string; color: string } & BoxProps
> = ({ bg = "transparent", color, ...rest }) => (
  <Box
    as="span"
    display="flex"
    role="img"
    aria-label="Text Contrast"
    css={{
      borderRadius: "50%",
      border: `2px solid currentColor`,
      alignItems: "center",
      justifyContent: "center",
      color,
      backgroundColor: bg,
      width: 50,
      height: 50,
    }}
    {...rest}
  >
    <TextBold as="span" textAlign="center" fontSize={6} aria-hidden="true">
      T
    </TextBold>
  </Box>
)
