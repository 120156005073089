import styled from "@emotion/styled"
import { Box, BoxProps } from ".."

export const ImageFluidContainer = styled<BoxProps>(Box)`
  svg,
  img {
    width: 100%;
    height: auto;
  }
`
