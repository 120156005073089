import * as React from "react"
import S400Woff2 from "../../../static/fonts/source-sans-pro-latin-400.woff2"
import S400Woff from "../../../static/fonts/source-sans-pro-latin-400.woff2"
import S400IWoff2 from "../../../static/fonts/source-sans-pro-latin-400italic.woff2"
import S400IWoff from "../../../static/fonts/source-sans-pro-latin-400italic.woff2"
import S700Woff2 from "../../../static/fonts/source-sans-pro-latin-700.woff2"
import S700Woff from "../../../static/fonts/source-sans-pro-latin-700.woff2"

// import "./font-faces.css"

export interface Fonts {
  [key: string]: {
    family: string
    weight: number | "normal"
  }
}

const systemSansFamily =
  '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;'

export const Fonts: Fonts = {
  mono: {
    family: `'IBM Plex Mono', "Lucida Console", Monaco, monospace`,
    weight: 400,
  },
  sans: {
    family: `'Source Sans Pro', "Open Sans", "Segoe UI", Tahoma, sans-serif, 'IBM Plex Sans', ${systemSansFamily}`,
    weight: 400,
  },
  sansBold: {
    family: `'Source Sans Pro', "Open Sans", "Arial Black", sans-serif, 'IBM Plex Sans', ${systemSansFamily}`,
    weight: 700,
  },
}
const DEFAULT_FONT = Fonts.sans
export const PreFetch: React.FC<> = () => (
  <>
    <link rel="prefetch" href={S700Woff2} />
    <link rel="prefetch" href={S400IWoff2} />
  </>
)
export const FontFaces = `
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src:
      url(${S400Woff2}) format('woff2'), /* Super Modern Browsers */
      url(${S400Woff}) format('woff'); /* Modern Browsers */
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src:
      url(${S400IWoff2}) format('woff2'), /* Super Modern Browsers */
      url(${S400IWoff}) format('woff'); /* Modern Browsers */
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src:
      url(${S700Woff2}) format('woff2'), /* Super Modern Browsers */
      url(${S700Woff}) format('woff'); /* Modern Browsers */
  }

`
export const globalCss = `font-family: ${DEFAULT_FONT.family};`
