import React from "react"

const SvgWarning = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M13.621 1.76h0a1.806 1.806 0 00-3.242 0L.906 21.058h0a1.521 1.521 0 001.366 2.192h19.456a1.521 1.521 0 001.366-2.192z"
      strokeLinecap="round"
      strokeWidth={1.5}
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    />
    <path
      d="M12 18.75h0a.375.375 0 10.375.375v0A.375.375 0 0012 18.75v0"
      strokeLinecap="round"
      strokeWidth={1.5}
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M12 15.75v-7.5"
    />
  </svg>
)

export default SvgWarning
