import * as React from "react"
import { Box, Text, TextBold, Label, Code, LinkAnchor } from "../../elements"
import { Colors } from "../../materials"

export const SnippetAccordionFlowchart: React.FC<{}> = () => (
  <>
    <Box display={["block", "block", "none"]}>
      <TextBold as="h5" fontSize={4} color={Colors.oc.blue[9]}>
        Which tecnhique should you use?
      </TextBold>

      <TextBold as="p" fontSize={3} mb={2}>
        Are animations a must?
      </TextBold>

      <Box pl={3} borderLeft={`4px solid ${Colors.oc.gray[4]}`}>
        <TextBold>No.</TextBold>
        <Box mt={3}>
          <Label>HTML</Label>
          <code css={{ whiteSpace: "nowrap" }}>{`hidden`}</code>
        </Box>
      </Box>

      <Box pl={3} borderLeft={`4px solid ${Colors.oc.gray[4]}`} mt={5}>
        <TextBold as="p">Yes.</TextBold>
        <Box mt={3} display="flex" alignItems="flex-end">
          <Box>
            <Label>HTML</Label>
            <code css={{ whiteSpace: "nowrap" }}>{`aria-hidden="true"`}</code>
          </Box>
          <Box mx={3}>
            <TextBold>+</TextBold>
          </Box>
          <code css={{ whiteSpace: "nowrap" }}>{`inert`}</code>
        </Box>
      </Box>

      <Box mt={7}>
        <TextBold as="p" fontSize={3}>
          Are you confortable with complex animations?
        </TextBold>
      </Box>

      <Box pl={3} borderLeft={`4px solid ${Colors.oc.gray[4]}`} mt={5}>
        <TextBold as="p"> Yes.</TextBold>
        <Text as="p" fontSize={3}>
          We recommend the{" "}
          <LinkAnchor
            href="https://aerotwist.com/blog/flip-your-animations/"
            target="_blank"
            rel="noopener noreferrer"
          >
            FLIP Animations
          </LinkAnchor>{" "}
          technique.
        </Text>
      </Box>

      <Box pl={3} borderLeft={`4px solid ${Colors.oc.gray[4]}`} mt={5}>
        <TextBold as="p">No.</TextBold>
        <Text as="p" fontSize={3}>
          We recommend the following CSS Animation, though you should be aware
          of its drawbacks below.
        </Text>
        <Box mt={5}>
          <Label>Example CSS Animation</Label>
          <Box display="inline-flex">
            <Code
              codeString={`.panel {
  max-height: 0;
  transition: max-height 0.5s ease-in;
}

.panel:not([aria-hidden="true"]) {
  max-height: 1000px;
}`}
              language="css"
            />
          </Box>
          <Box mt={5}>
            <Label as="span" css={{ display: "block" }}>
              <span role="img" aria-label="warning">
                ⚠️
              </span>{" "}
              Warning
            </Label>
            <Text as="p" mt={2}>
              This technique will have some drawbacks since it will not take the
              height of the content to be animated into consideration.
            </Text>
            <Text as="p">
              Another major downside, is that the animation will not be very
              performant since it is using <code>{`max-height`}</code>,
              triggering{" "}
              <LinkAnchor
                href="https://csstriggers.com/max-height"
                target="_blank"
                rel="noopener noreferrer"
              >
                Layout, Paint, and Composite
              </LinkAnchor>{" "}
              events on every frame.
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
    <Box display={["none", "none", "block"]}>
      <Box display="flex">
        <Box width={1 / 5} />
        <Box display="flex" flexDirection="column" alignItems="center">
          <TextBold
            css={{ display: "inline-block" }}
            as="h5"
            textAlign="center"
            fontSize={4}
            color={Colors.oc.blue[9]}
          >
            Which tecnhique should you use?
          </TextBold>
          <Box mx="auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="3"
              height="47"
              viewBox="0 0 3 47"
            >
              <path
                fill="none"
                stroke="#B9B9B9"
                strokeWidth="3"
                d="M1.5,1.5 L1.5,45.5"
                strokeLinecap="square"
              />
            </svg>
          </Box>
          <TextBold textAlign="center" mb={2}>
            Are animations a must?
          </TextBold>
          <Box display="flex" justifyContent="center">
            <Box css={{ position: "relative" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
              >
                <path
                  fill="none"
                  stroke="#B9B9B9"
                  strokeWidth="3"
                  d="M97.5,2.5 L2.41582676,97.5841732"
                  strokeLinecap="square"
                />
              </svg>
              <Box
                css={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: Colors.oc.blue[0],
                  padding: 4,
                  borderRadius: 8,
                }}
              >
                <TextBold>No</TextBold>
              </Box>
            </Box>
            <Box css={{ position: "relative" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
              >
                <path
                  fill="none"
                  stroke="#B9B9B9"
                  strokeWidth="3"
                  d="M97.5,2.5 L2.41582676,97.5841732"
                  transform="matrix(-1 0 0 1 100 0)"
                  strokeLinecap="square"
                />
              </svg>
              <Box
                css={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: Colors.oc.blue[0],
                  padding: 4,
                  borderRadius: 8,
                }}
              >
                <TextBold>Yes</TextBold>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display="flex" my={4}>
        <Box width={0.25} display="flex" justifyContent="flex-end">
          <Box>
            <Label>HTML</Label>
            <code css={{ whiteSpace: "nowrap" }}>{`hidden`}</code>
          </Box>
        </Box>
        <Box width={0.25} />

        <Box flex="1 1 auto" display="flex">
          <Box display="flex" alignItems="flex-end">
            <Box>
              <Label>HTML</Label>
              <code css={{ whiteSpace: "nowrap" }}>{`aria-hidden="true"`}</code>
            </Box>
            <Box mx={3}>
              <TextBold>+</TextBold>
            </Box>
            <code css={{ whiteSpace: "nowrap" }}>{`inert`}</code>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Box width={0.4} />
        <Box flex="1 1 auto">
          <Box display="inline-flex" flexDirection="column" alignItems="center">
            <Box mx="auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="47"
                viewBox="0 0 3 47"
              >
                <path
                  fill="none"
                  stroke="#B9B9B9"
                  strokeWidth="3"
                  d="M1.5,1.5 L1.5,45.5"
                  strokeLinecap="square"
                />
              </svg>
            </Box>
            <TextBold
              mb={2}
              css={{ display: "inline-block" }}
              textAlign="center"
            >
              Are you confortable with complex animations?
            </TextBold>

            <Box display="flex" justifyContent="center">
              <Box css={{ position: "relative" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                >
                  <path
                    fill="none"
                    stroke="#B9B9B9"
                    strokeWidth="3"
                    d="M97.5,2.5 L2.41582676,97.5841732"
                    strokeLinecap="square"
                  />
                </svg>
                <Box
                  css={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: Colors.oc.blue[0],
                    padding: 4,
                    borderRadius: 8,
                  }}
                >
                  <TextBold>No</TextBold>
                </Box>
              </Box>
              <Box css={{ position: "relative" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                >
                  <path
                    fill="none"
                    stroke="#B9B9B9"
                    strokeWidth="3"
                    d="M97.5,2.5 L2.41582676,97.5841732"
                    transform="matrix(-1 0 0 1 100 0)"
                    strokeLinecap="square"
                  />
                </svg>
                <Box
                  css={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: Colors.oc.blue[0],
                    padding: 4,
                    borderRadius: 8,
                  }}
                >
                  <TextBold>Yes</TextBold>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <Box display="flex">
          <Box width={0.05} />
          <Box>
            <Label>Example CSS Animation</Label>
            <Box display="inline-flex">
              <Code
                codeString={`.panel {
    max-height: 0;
    transition: max-height 0.5s ease-in;
  }

  .panel:not([aria-hidden="true"]) {
    max-height: 1000px;
  }`}
                language="css"
              />
            </Box>
            <Box mt={4}>
              <Label as="span" css={{ display: "block" }}>
                <span role="img" aria-label="warning">
                  ⚠️
                </span>{" "}
                Warning
              </Label>
              <Text mt={2}>
                This technique will have some drawbacks since it will not take
                the height of the content to be animated into consideration.
              </Text>
              <Text>
                Another major downside, is that the animation will not be very
                performant since it is using <code>{`max-height`}</code>,
                triggering{" "}
                <LinkAnchor
                  href="https://csstriggers.com/max-height"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Layout, Paint, and Composite
                </LinkAnchor>{" "}
                events on every frame.
              </Text>
            </Box>
          </Box>
          <Box width={0.15} />
          <Box>
            <Label>JS Animation</Label>
            <Box>
              <Text>
                We recommend the{" "}
                <LinkAnchor
                  href="https://aerotwist.com/blog/flip-your-animations/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FLIP Animations
                </LinkAnchor>{" "}
                technique.
              </Text>
            </Box>
            {/* <Code codeString={
              `
  const collapsed = panel.getBoundingClientRect();
  panel.style.height = 'auto';
  const expanded = panel.getBoundingClientRect();

  // diff dimensions
  const invertedTop = collapsed.top - expanded.top;
  const invertedLeft = collapsed.left - expanded.left;
  const invertedWidth = expanded.width > 0 ? collapsed.width / expanded.width : 0;
  const invertedHeight = expanded.height > 0 ? collapsed.height / expanded.height : 0;


  panel.style.transform = \`
    translate(\${invertedLeft}px,\${invertedTop}px)
    scale(\${invertedWidth}, \${invertedHeight})\`;

  requestAnimationFrame(function(){
    panel.style.transform = 'transform 330ms ease-in';
    // Clear styles
    panel.style.transform = '';
    // On transitionEnd remove the classes used control transitions
    panel.addEventListener('transitionend', function(){
      panel.style.transformOrigin = '';
      panel.style.transform = '';
      panel.removeEventListener('transitionend', false);  
    });
  }); 
  `} language='javascript' ></Code> */}
          </Box>
        </Box>
      </Box>
    </Box>
  </>
)
