import * as React from "react"
import { Colors, Fonts } from "../../materials"
import {
  Box,
  LinkAnchor,
  Code,
  ButtonIcon,
  Input,
  List,
  ListItem,
} from "../../elements"

const Nav = props => (
  <Box
    as="nav"
    css={{
      ol: {
        listStyleType: "none",
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: 0,
      },
      li: {
        display: "flex",
        alignItems: "center",
      },
      "li:after": {
        content: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KCTxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTguNTkgMTYuNTlMMTMuMTcgMTIgOC41OSA3LjQxIDEwIDZsNiA2LTYgNi0xLjQxLTEuNDF6IiAvPgoJPHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgwVjB6IiAvPgo8L3N2Zz4K")`,
        height: 24,
        paddingLeft: 6,
        paddingRight: 6,
      },
      "li:last-of-type:after": {
        display: "none",
      },
      a: {
        color: Colors.oc.blue[9],
        fontWeight: Fonts.sansBold.weight,
      },
      "a[aria-current]": {
        color: Colors.oc.black,
        textDecoration: "none",
        pointerEvents: "none",
      },
    }}
    {...props}
  />
)

export const SnippetBreadcrumbs: React.FC<{}> = () => (
  <Box display="flex" flexWrap="wrap" bg={Colors.oc.gray[1]}>
    <Box
      width={[1, 1, 0.5]}
      py={[3]}
      display="flex"
      alignItems="center"
      justifyContent="center"
      aria-hidden="true"
    >
      <Nav aria-label="breadcrumbs">
        <ol>
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#">Parent</a>
          </li>
          <li>
            <a href="#" aria-current="location">
              Current
            </a>
          </li>
        </ol>
      </Nav>
    </Box>
    <Box width={[1, 1, 0.5]}>
      <Code
        codeString={`<nav aria-label="breadcrumbs">
  <ol>
    <li>
      <a href="/">
        Home
      </a>
    </li>
    <li>
      <a href="/parent">
        Parent
      </a>
    </li>
    <li>
      <a
        href="/parent/current"
        aria-current="location"
      >
        Current
      </a>
    </li>
  </ol>
</nav>`}
        language="html"
      />
    </Box>
  </Box>
)
