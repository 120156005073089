import * as React from "react"
import { BoxProps, Pill } from ".."
import { Fonts } from "../../materials"

export const FormMessage: React.FC<BoxProps> = props => (
  <Pill
    py={4}
    px={5}
    width={1}
    position="relative"
    css={{
      textTransform: "none",
      fontWeight: Fonts.sans.weight,
      fontFamily: Fonts.sans.family,
    }}
    {...(props || {})}
  />
)
