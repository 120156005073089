import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import {
  Box,
  ButtonGatsby,
  Container,
  Heading,
  Text,
  TextBold,
  List,
  ListItem,
  ButtonSize,
} from "../../elements"
import { Colors, Icons } from "../../materials"

export const ConsultationTeaser: React.FC<{
  previousSectionBg?: string
  nextSectionBg?: string
  separatorColor?: string
  bg?: string
  borderColor?: string
  title?: () => React.ReactNode
}> = ({
  previousSectionBg = Colors.oc.gray[0],
  nextSectionBg = Colors.oc.gray[1],
  separatorColor = Colors.oc.gray[4],
  bg = Colors.oc.blue[0],
  borderColor = Colors.oc.gray[4],
  title,
}) => {
  const { joao } = useStaticQuery(graphql`
    query {
      joao: file(relativePath: { eq: "photos/joao.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Box
      as="aside"
      css={{
        backgroundImage: `linear-gradient(
          ${previousSectionBg} 0%,
          ${previousSectionBg} 50%,
          ${separatorColor} 50%,
          ${nextSectionBg} calc(50% + 1px),
          ${nextSectionBg} 100%
        )`,
      }}
    >
      <Container>
        <Box display="flex" justifyContent="center">
          <Box
            py={6}
            px={[4, 5, 9]}
            bg={bg}
            borderRadius={10}
            border={`2px solid ${borderColor}`}
          >
            <Box display="flex" justifyContent="center">
              <Box
                width={48}
                height={48}
                border={`2px solid ${Colors.oc.blue[9]}`}
                bg={Colors.oc.blue[1]}
                color={Colors.oc.blue[9]}
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icons.SvgPhoneActionsWait
                  css={{ width: 24, height: 24, g: { strokeWidth: 2 } }}
                />
              </Box>
              <Box ml={4}>
                <Box width={48} height={48} borderRadius={"50%"}>
                  <Img fixed={joao.childImageSharp.fixed} alt="João Picture" />
                </Box>
              </Box>
            </Box>
            <Box mt={4} />
            <Heading color={Colors.oc.blue[9]} textAlign="center">
              {title ? title() : <>The first consultation is on us.</>}
            </Heading>
            <Box mt={4} />

            <Box maxWidth="copy">
              <Text textAlign="center" color={Colors.oc.blue[9]}>
                We'll discuss your biggest accessibility problems, and figure
                out what you need to do to make your website more accessible.
              </Text>
              <Box mt={4} />
              <Text textAlign="center" color={Colors.oc.blue[9]}>
                In this <strong>free 45 min session</strong> we will:
              </Text>
            </Box>

            <Box mt={4} />
            <Box display="flex" justifyContent="center">
              <List maxWidth="50ch">
                <ListItem display="flex" alignItems="center" mb={1}>
                  <Box
                    flex="0 0 auto"
                    width={18}
                    height={18}
                    color={Colors.oc.blue[9]}
                    mr={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    css={{
                      svg: { width: "100%" },
                    }}
                  >
                    <Icons.SvgCheckCircle />
                  </Box>
                  <Text>Analyse your website or product.</Text>
                </ListItem>
                <ListItem display="flex" alignItems="center" mb={1}>
                  <Box
                    flex="0 0 auto"
                    width={18}
                    height={18}
                    color={Colors.oc.blue[9]}
                    mr={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    css={{
                      svg: { width: "100%" },
                    }}
                  >
                    <Icons.SvgCheckCircle />
                  </Box>
                  <Text>Review some accessibility issues.</Text>
                </ListItem>

                <ListItem display="flex" alignItems="center" mb={1}>
                  <Box
                    flex="0 0 auto"
                    width={18}
                    height={18}
                    color={Colors.oc.blue[9]}
                    mr={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    css={{
                      svg: { width: "100%" },
                    }}
                  >
                    <Icons.SvgCheckCircle />
                  </Box>
                  <Text>Make a plan on how to improve.</Text>
                </ListItem>
              </List>
            </Box>

            <Box mt={6} />
            <Box display="flex" justifyContent="center">
              <ButtonGatsby
                to="/free-accessibility-consultation/"
                size={ButtonSize.LARGE}
              >
                Schedule Your Free Session
              </ButtonGatsby>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
