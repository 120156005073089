import { default as SvgArrowDown } from "./generated/ArrowDown"
import { default as SvgArrowLeft1 } from "./generated/ArrowLeft1"
import { default as SvgArrowLeft } from "./generated/ArrowLeft"
import { default as SvgArrowRight1 } from "./generated/ArrowRight1"
import { default as SvgArrowRight } from "./generated/ArrowRight"
import { default as SvgButtonLoopArrow } from "./generated/ButtonLoopArrow"
import { default as SvgCheckCircle1 } from "./generated/CheckCircle1"
import { default as SvgCog } from "./generated/Cog"
import { default as SvgColorBrushPaint } from "./generated/ColorBrushPaint"
import { default as SvgCursorHand } from "./generated/CursorHand"
import { default as SvgEarth1 } from "./generated/Earth1"
import { default as SvgFitnessBiceps } from "./generated/FitnessBiceps"
import { default as SvgGlassesRetro1 } from "./generated/GlassesRetro1"
import { default as SvgHouse1 } from "./generated/House1"
import { default as SvgLock1 } from "./generated/Lock1"
import { default as SvgSingleManCircle } from "./generated/SingleManCircle"
import { default as SvgStudyBrain } from "./generated/StudyBrain"
import { default as SvgTouchFinger } from "./generated/TouchFinger"
import { default as SvgView1 } from "./generated/View1"
import { default as SvgInsertLink } from "./generated/IcInsertLink"
import { default as SvgToday } from "./generated/IcToday"
import { default as SvgClose } from "./generated/IcClose"
import { default as SvgKeyboardArrowRight } from "./generated/IcKeyboardArrowRight"
import { default as SvgCheckCircle } from "./generated/IcCheckCircle"
import { default as SvgSend } from "./generated/IcSend"
import { default as SvgWarning } from "./generated/Warning"
import { default as SvgKeyboardRight } from "./generated/KeyboardRight"
import { default as SvgDashboardStroke } from "./generated/DashboardStroke"
import { default as SvgVideoGamePacmanEnemy } from "./generated/VideoGamePacmanEnemy"
import { default as SvgLoveIt } from "./generated/LoveIt"
import { default as SvgBulbShine } from "./generated/BulbShine"
import { default as SvgHand } from "./generated/Hand"
import { default as SvgQuestionHelpMessage } from "./generated/QuestionHelpMessage"
import { default as SvgOpenInNew } from "./generated/IcOpenInNew"
import { default as SvgMessagesBubbleSquareInformation } from "./generated/MessagesBubbleSquareInformation"
import { default as SvgInformationCircle } from "./generated/InformationCircle"
import { default as SvgPhoneActionsWait } from "./generated/PhoneActionsWait"

export type Icons = Record<string, any>
export const Icons: Icons = {
  SvgArrowDown,
  SvgArrowLeft,
  SvgArrowRight,
  SvgArrowLeft1,
  SvgArrowRight1,
  SvgButtonLoopArrow,
  SvgCheckCircle1,
  SvgEarth1,
  SvgLock1,
  SvgTouchFinger,
  SvgHouse1,
  SvgCog,
  SvgColorBrushPaint,
  SvgSingleManCircle,
  SvgGlassesRetro1,
  SvgCursorHand,
  SvgStudyBrain,
  SvgFitnessBiceps,
  SvgView1,
  SvgInsertLink,
  SvgToday,
  SvgClose,
  SvgKeyboardArrowRight,
  SvgCheckCircle,
  SvgSend,
  SvgWarning,
  SvgKeyboardRight,
  SvgDashboardStroke,
  SvgVideoGamePacmanEnemy,
  SvgLoveIt,
  SvgBulbShine,
  SvgHand,
  SvgQuestionHelpMessage,
  SvgOpenInNew,
  SvgMessagesBubbleSquareInformation,
  SvgInformationCircle,
  SvgPhoneActionsWait,
}
