import * as React from "react"
import { Box, List, ListItem, Label, LinkAnchor } from "../../elements"
import { Colors } from "../../materials"

type Props = {
  tree: Tree
  id?: string
}

export type Tree = {
  nodes: TreeNode[]
}
export type TreeNode = {
  selfText: string
  selfId: string
  nodes?: TreeNode[]
}

export const TOC: React.FC<Props> = ({ tree = { nodes: [] }, id }) => {
  const onAnchorClick: React.MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault()

    const href = event.currentTarget.getAttribute("href")
    if (!href) {
      return
    }
    const heading = document.getElementById(href.slice(1))
    if (heading) {
      heading.scrollIntoView({
        behavior: `smooth`,
        block: `start`,
      })
    }
  }
  return (
    <Box
      borderLeft={`4px solid ${Colors.oc.gray[2]}`}
      pl={4}
      as="nav"
      aria-labelledby={`toc-title-${id}`}
    >
      <Label as="h2" id={`toc-title-${id}`}>
        Table of Contents
      </Label>
      <List mt={2} fontSize={2}>
        {tree.nodes.map((h2, index) => (
          <ListItem key={index}>
            <LinkAnchor
              onClick={onAnchorClick}
              href={`#${h2.selfId}`}
              css={{
                textDecoration: "none",
              }}
              dangerouslySetInnerHTML={{
                __html: h2.selfText,
              }}
            />

            {(h2.nodes || []).length > 0 ? (
              <List>
                {(h2.nodes || []).map((h3, h3Index) => (
                  <ListItem key={h3Index} pl={6}>
                    <LinkAnchor
                      css={{
                        textDecoration: "none",
                      }}
                      onClick={onAnchorClick}
                      href={`#${h3.selfId}`}
                      dangerouslySetInnerHTML={{
                        __html: h3.selfText,
                      }}
                    />
                    {(h3.nodes || []).length > 0 ? (
                      <List>
                        {(h3.nodes || []).map((h4, h4Index) => (
                          <ListItem key={h4Index} pl={6}>
                            <LinkAnchor
                              onClick={onAnchorClick}
                              href={`#${h4.selfId}`}
                              dangerouslySetInnerHTML={{
                                __html: h4.selfText,
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : null}
                  </ListItem>
                ))}
              </List>
            ) : null}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
