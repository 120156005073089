import * as React from "react"
import { Box, BoxProps } from ".."
import { Colors } from "../../materials"

export enum PillType {
  SUCCESS,
  ERROR,
  MAIN,
  INHERIT,
}

export enum PillSize {
  SMALL,
  MEDIUM,
}

const CSS_BY_TYPE: Record<PillType, object> = {
  [PillType.SUCCESS]: {
    backgroundColor: Colors.tw.green["200"],
    color: Colors.tw.green["800"],
    borderColor: Colors.tw.green["300"],
  },
  [PillType.ERROR]: {
    backgroundColor: Colors.tw.red["200"],
    color: Colors.tw.red["800"],
    borderColor: Colors.tw.red["300"],
  },
  [PillType.MAIN]: {
    backgroundColor: "transparent",
    color: Colors.oc.blue[9],
    borderColor: "currentColor",
  },
  [PillType.INHERIT]: {
    backgroundColor: "transparent",
    color: "inherit",
    borderColor: "currentColor",
  },
}

const CSS_BY_SIZE: Record<PillSize, object> = {
  [PillSize.MEDIUM]: {
    fontSize: "1rem",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  [PillSize.SMALL]: {
    fontSize: ".6875rem",
    paddingLeft: "3px",
    paddingRight: "3px",
  },
}

type PillProps = BoxProps & {
  type?: PillType
  size?: PillSize
}

export const Pill: React.FC<PillProps> = ({
  type = PillType.SUCCESS,
  size = PillSize.MEDIUM,
  children,
  css = {},
  ...rest
}) => (
  <Box
    as="span"
    display="inline-block"
    css={{
      textTransform: "uppercase",
      borderRadius: "4px",
      border: `1px solid pink`,
      lineHeight: 1.3,
      letterSpacing: "0.025em",
      fontWeight: "700",
      textDecoration: "none",
      ...CSS_BY_TYPE[type],
      ...CSS_BY_SIZE[size],
      ...css,
    }}
    {...rest}
  >
    {children}
  </Box>
)
