import * as React from "react"
import styled from "@emotion/styled"
import { Breakpoints, Colors, Fonts, MaxWidths, Spaces } from "../../materials"

import {
  Tabs as TabsBase,
  TabList as TabListBase,
  Tab as TabBase,
  TabPanels as TabPanelsBase,
  TabPanel as TabPanelBase,
} from "@reach/tabs"

export const Tabs = styled(TabsBase)({
  position: "relative",
})

export const TabList = styled(TabListBase)({
  display: "flex",

  // TABS ON BOTTON ON MOBILE
  position: "fixed",
  bottom: 0,
  zIndex: 3,
  background: Colors.oc.blue[0],
  width: "100vw",
  // paddingLeft: `env(safe-area-inset-left)`,
  // paddingRight: `env(safe-area-inset-right)`,
  paddingBottom: `constant(safe-area-inset-bottom)`,
  paddingBottom: `env(safe-area-inset-bottom)`,
  background: Colors.oc.white,
  [`@media (min-width: ${Breakpoints[1]})`]: {
    // Undo TABS ON BOTTOM
    position: "static",
    width: "auto",
    background: "transparent",
    maxWidth: MaxWidths.l,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 0,
  },
})

export const Tab = styled(props => <TabBase {...props} />)({
  fontFamily: Fonts.sansBold.family,
  fontWeight: Fonts.sansBold.weight,
  textDecoration: "underline",
  display: "flex",
  margin: 0,
  cursor: "pointer",
  appearance: "none",
  whiteSpace: "nowrap",

  // MOBILE
  marginLeft: 0,
  flex: "1 1 auto",
  fontSize: ".75rem",
  paddingTop: ".4em",
  paddingBottom: ".4em",
  paddingLeft: ".6em",
  paddingRight: ".6em",
  minHeight: "44px",
  width: "25%",
  borderRadius: 0,
  border: `1px solid ${Colors.oc.blue[9]}`,
  // borderBottom: 0,
  borderRight: 0,
  background: Colors.oc.blue[9],
  color: Colors.oc.white,
  "&:first-of-type": {
    borderLeft: 0,
  },
  // TABLET
  [`@media (min-width: ${Breakpoints[1]})`]: {
    width: "auto",
    marginLeft: ".5em",
    padding: "0.6em 1em",
    background: "transparent",
    border: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    fontSize: "1rem",
    flex: "0 1 auto",
    color: Colors.oc.gray[7],
  },
  // LAPTOP
  [`@media (min-width: ${Breakpoints[2]})`]: {
    marginLeft: "1.5em",
    width: "auto",
  },
  "&:active": {
    transform: "translateY(1px)",
    borderBottomColor: "transparent",
  },

  "&:focus:not(.focus-visible)": {
    outline: "none",
  },
  "&.focus-visible": {
    outline: `2px solid ${Colors.oc.blue[9]}`,
    outlineOffset: 4,
  },

  // "&:focus-within :focus:not(.focus-visible)": {
  //   outline: "none",
  // },
  "&:disabled": {
    opacity: 0.25,
    cursor: "default",
  },
  "&:hover": {
    color: Colors.tw.yellow["200"],
    [`@media (min-width: ${Breakpoints[1]})`]: {
      color: Colors.oc.blue[9],
    },
  },
  "&[data-selected]": {
    textDecoration: "none",
    color: Colors.oc.blue[9],
    background: Colors.oc.white,
    // MOBILE
    borderTop: `1px solid ${Colors.oc.blue[9]}`,
    borderLeft: `1px solid ${Colors.oc.blue[9]}`,
    borderBottom: `1px solid ${Colors.oc.blue[9]}`,
    borderRight: 0,
    border: `4px solid ${Colors.oc.blue[9]}`,
    borderRightWidth: "3px",
    "&:last-child": {
      borderRightWidth: "4px",
    },

    // TABLET
    [`@media (min-width: ${Breakpoints[1]})`]: {
      border: `1px solid ${Colors.oc.blue[9]}`,
      borderBottom: 0,
      borderRightWidth: "1px",
      "&:last-child": {
        borderRightWidth: "1px",
      },
    },
  },
})

export const TabPanels = styled(TabPanelsBase)({
  background: Colors.oc.white,
  borderTop: `1px solid ${Colors.oc.gray[4]}`,
  marginTop: -1,
  display: "block",
  [`@media (min-width: ${Breakpoints[1]})`]: {
    borderTop: `1px solid ${Colors.oc.blue[9]}`,
  },
})

export const TabPanel = styled(TabPanelBase)`
  outline: none;
  max-width: ${MaxWidths.l};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${Spaces[5]}px;
  padding-right: ${Spaces[5]}px;
  @media (min-width: ${Breakpoints[2]}) {
    padding-left: ${Spaces[6]}px;
    padding-right: ${Spaces[6]}px;
  }
`
