import styled from "@emotion/styled"
import { Link as GatsbyLink } from "gatsby"
import * as React from "react"
import Logo from "../../../static/logo.inline.svg"
import {
  Box,
  BoxProps,
  Container,
  List,
  ListItem,
  ButtonMenu,
  Pill,
  PillType,
  PillSize,
} from "../../elements"
import { Colors, Fonts } from "../../materials"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"
import "@reach/skip-nav/styles.css"

const LogoLink = styled(GatsbyLink)`
  display: block;
  color: ${Colors.oc.white};
  transition: background-color 150ms ease-in, color 150ms ease-in;
  svg {
    display: block;
    width: 120px;
    height: auto;
    fill: currentColor;
  }

  &:hover:not(:focus):not(:active) {
    color: ${Colors.tw.yellow["200"]};
  }
  &:active {
    transform: translateY(1px);
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }

  &.focus-visible {
    outline: 2px solid ${Colors.tw.yellow["200"]};
    outline-offset: 4px;
  }
`

// @ts-ignore
const HeaderEl = styled<BoxProps>(Box)`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  background: ${Colors.oc.blue[9]};
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  min-height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 44px;
  display: block;
`

const HeaderLink = styled(LogoLink)({
  fontWeight: Fonts.sansBold.weight,
  fontFamily: Fonts.sansBold.family,
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  minHeight: "44px",
})

export const Header: React.FC<{}> = () => {
  const [isOpened, setIsOpened] = React.useState<boolean>(false)

  return (
    <>
      <SkipNavLink />
      <HeaderEl as="header" py={3}>
        <Container
          maxWidth="l"
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <LogoLink to="/" aria-label="Aditus Homepage">
            <Logo aria-hidden="true" />
          </LogoLink>

          <Box display={["block", "block", "none"]} ml="auto">
            <ButtonMenu
              isActive={isOpened}
              onClick={() => setIsOpened(!isOpened)}
              aria-expanded={isOpened}
              aria-controls="menu"
            />
          </Box>

          <Box
            as="nav"
            id="navigation"
            role="navigation"
            aria-label="Main"
            width={[1, 1, "auto"]}
          >
            <Box
              mt={2}
              mb={2}
              display={[
                isOpened ? "block" : "none",
                isOpened ? "block" : "none",
                "none",
              ]}
              height={1}
              bg={Colors.oc.white}
              css={{
                opacity: 0.6,
              }}
            />
            <List
              flexDirection={["column", "column", "row"]}
              alignItems={["flex-end", "flex-end", "center"]}
              mx={[0, 0, -5]}
              width={[1, 1, "auto"]}
              id="menu"
              display={[
                isOpened ? "flex" : "none",
                isOpened ? "flex" : "none",
                "flex",
              ]}
            >
              <ListItem px={[0, 0, 5]} display="block">
                <HeaderLink to={"/accessibility-audit/"}>
                  Accessibility Audit
                </HeaderLink>
              </ListItem>
              <ListItem px={[0, 0, 5]}>
                <HeaderLink to={"/button-contrast-checker/"}>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={["row-reverse", "row-reverse", "row"]}
                  >
                    Button Contrast Checker
                    <Box ml={[0, 0, 2]} mt={-2} mr={[1, 1, 0]}>
                      <Pill type={PillType.INHERIT} size={PillSize.SMALL}>
                        Free
                      </Pill>
                    </Box>
                  </Box>
                </HeaderLink>
              </ListItem>
            </List>
            <Box
              mt={2}
              display={[
                isOpened ? "block" : "none",
                isOpened ? "block" : "none",
                "none",
              ]}
            />
          </Box>
        </Container>
      </HeaderEl>
      <SkipNavContent />
    </>
  )
}
