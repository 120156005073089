import React from "react"

const SvgMessagesBubbleSquareInformation = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      strokeLinecap="round"
      strokeWidth={1.5}
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    >
      <path d="M21.75 18.75h-10.5l-6 4.5v-4.5h-3a1.5 1.5 0 01-1.5-1.5v-15a1.5 1.5 0 011.5-1.5h19.5a1.5 1.5 0 011.5 1.5v15a1.5 1.5 0 01-1.5 1.5z" />
      <path d="M15 14.25h-.75a1.5 1.5 0 01-1.5-1.5V9a.75.75 0 00-.75-.75h-.75" />
      <path d="M12.375 4.5h0" />
      <path d="M12.75 4.875a.375.375 0 00-.375-.375" />
      <path d="M12.375 4.5a.375.375 0 10.375.375" />
    </g>
  </svg>
)

export default SvgMessagesBubbleSquareInformation
