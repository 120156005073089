import * as React from "react"
import { ContrastScore } from "../../../backend/functions/contrast-check/interfaces"
import { Box, Label, TextBold } from "../../elements"
import { Colors, Icons } from "../../materials"
import { IconContrast } from "../../components"

export const IconObjectBackgroundColor: React.FC<{
  objectBackgroundColor: string
  containerBackgroundColor: string
  contrastScore: ContrastScore
  showWarning?: boolean
}> = ({
  objectBackgroundColor,
  containerBackgroundColor,
  contrastScore,
  showWarning = false,
}) => {
  return (
    <>
      <Box>
        <Box display="flex" justifyContent="center">
          <IconContrast
            bg={containerBackgroundColor}
            object={objectBackgroundColor}
          />
        </Box>

        <Box mt={3} />

        <Box
          flex="1 1 auto"
          as="p"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Label as="span" css={{ textAlign: "center" }}>
            {Number(contrastScore.contrast).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </Label>

          <TextBold
            as="span"
            color={
              showWarning
                ? Colors.oc.gray[7]
                : contrastScore.passesAA
                ? Colors.tw.green["700"]
                : Colors.tw.red["700"]
            }
            css={{ textAlign: "center" }}
            fontSize={4}
          >
            {showWarning ? (
              <Box
                width={20}
                display="flex"
                alignItems="flex-end"
                css={{
                  marginTop: 3,
                  svg: {
                    width: "100%",
                    height: "auto",
                    "path:nth-child(1)": {
                      fill: Colors.tw.yellow["300"],
                    },
                    "path:nth-child(2)": {},
                    "path:nth-child(3)": {},
                  },
                }}
              >
                <Icons.SvgWarning />
              </Box>
            ) : contrastScore.passesAA ? (
              "AA"
            ) : (
              "FAIL"
            )}
          </TextBold>
        </Box>
      </Box>
    </>
  )
}
