import * as React from "react"
import { Colors } from "../../materials"
import {
  Box,
  LinkAnchor,
  Code,
  ButtonIcon,
  Input,
  List,
  ListItem,
} from "../../elements"

export const SnippetButtonIcon: React.FC<{}> = () => (
  <Box display="flex" flexWrap="wrap" bg={Colors.oc.gray[1]}>
    <Box
      width={[1, 1, 0.4]}
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={4}
      aria-hidden="true"
    >
      <ButtonIcon aria-label="Close">
        <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59
                12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            fill="currentColor"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </ButtonIcon>
    </Box>
    <Box width={[1, 1, 0.6]}>
      <Code
        codeString={`<button aria-label="Close">
  <svg
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
  >
    <path
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5
         6.41 10.59 12 5 17.59 6.41 19 12
         13.41 17.59 19 19 17.59 13.41 12z" />
  </svg>
</button>`}
        language="html"
      />
    </Box>
  </Box>
)
