export type FontSizes = number[]
export const BaseSize = 18
export const BaseSizePercentage = `${(BaseSize / 16) * 100}%`
export const FontSizes: FontSizes = [
  12,
  14,
  16,
  18,
  20,
  22,
  26,
  32,
  36,
  40,
  48,
  64,
]
// .map(
//   px => `${px / BaseSize}rem`
// )
