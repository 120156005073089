import React from "react"

const SvgVideoGamePacmanEnemy = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 10.5a9.75 9.75 0 0119.5 0v9c0 2.071-1.343 3.75-3 3.75s-3-1.679-3-3.75a3.75 3.75 0 01-7.5 0c0 2.071-1.343 3.75-3 3.75s-3-1.679-3-3.75z"
    />
    <ellipse
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      cx={7.5}
      cy={12}
      rx={2.25}
      ry={3.75}
    />
    <ellipse
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      cx={15}
      cy={12}
      rx={2.25}
      ry={3.75}
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 12.375A.375.375 0 016.375 12"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.125 12a.375.375 0 01-.375.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.25 11.625a.375.375 0 01.375.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.875 12a.375.375 0 01.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.25 12.375a.375.375 0 01-.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.625 12a.375.375 0 01-.375.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 11.625a.375.375 0 01.375.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.375 12a.375.375 0 01.375-.375"
    />
  </svg>
)

export default SvgVideoGamePacmanEnemy
