import styled from "@emotion/styled"
import * as React from "react"
import { Box, BoxProps } from "../"

export const List = ({ css = {}, ...rest }) => (
  <Box
    as="ul"
    px={0}
    py={0}
    mx={0}
    my={0}
    css={{
      listStyle: "none",
      ...css,
    }}
    {...rest}
  />
)

export const ListOrdered = props => <List as="ol" {...props} />

export const ListItem = styled(Box)<BoxProps>({}).withComponent("li")
