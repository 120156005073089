import * as React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import styled, { FunctionInterpolation } from "@emotion/styled"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { Colors, Fonts, Icons } from "../../materials"
import { Box } from "../Box"

const styles: FunctionInterpolation<{}> = () => ({
  // override button
  background: "none",
  border: "none",
  borderRadius: 0,
  padding: 0,
  fontSize: "inherit",
  display: "inline",
  alignItems: "center",
  cursor: "pointer",
  appearance: "none",

  // override link

  // styles
  letterSpacing: "0.025em",
  lineHeight: 1.2,
  textDecoration: "underline",
  fontWeight: Fonts.sansBold.weight,
  fontFamily: Fonts.sansBold.family,
  color: Colors.oc.blue[9],
  outline: "none",
  orphans: 2,

  "&:hover": {
    color: Colors.oc.gray[7],
  },

  "&:focus:not(.focus-visible)": {
    outline: "none",
  },
  "&.focus-visible": {
    outline: `2px solid ${Colors.oc.blue[9]}`,
    outlineOffset: 4,
  },
})

// @ts-ignore
const ElLinkGatsby = styled(props => <GatsbyLink {...props} />)<Props>(styles)
// @ts-ignore
const ElLinkAnchor = styled(props => <OutboundLink {...props} />)<Props>(styles)
// @ts-ignore
const ElLinkButton = styled.button<{}>(styles)

export const LinkGatsby: React.FC<GatsbyLinkProps<{}>> = props => {
  const { target, children, ...rest } = props
  if (target === "_blank") {
    return (
      <ElLinkGatsby target={target} {...rest}>
        {children}
        <Box
          as="span"
          display="inline"
          css={{ svg: { width: "16px", height: "16px" } }}
          ml={0}
        >
          <span className="visually-hidden">(opens in a new tab)</span>
          <Icons.SvgOpenInNew aria-hidden="true" />
        </Box>
      </ElLinkGatsby>
    )
  }
  return <ElLinkGatsby {...props} />
}

export const LinkAnchor: React.FC<
  React.PropsWithRef<JSX.IntrinsicElements["a"]>
> = props => {
  const { target, children, ...rest } = props
  if (target === "_blank") {
    return (
      <ElLinkAnchor target={target} {...rest}>
        {children}
        <Box
          as="span"
          display="inline"
          css={{ svg: { width: "16px", height: "16px" } }}
          ml={0}
        >
          <span className="visually-hidden">(opens in a new tab)</span>
          <Icons.SvgOpenInNew aria-hidden="true" />
        </Box>
      </ElLinkAnchor>
    )
  }

  return <ElLinkAnchor {...props} />
}

export const LinkButton: React.FC<
  React.PropsWithRef<JSX.IntrinsicElements["button"]>
> = props => <ElLinkButton {...props} />
