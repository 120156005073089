import React from "react"

const SvgStudyBrain = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <path d="M11.245 7.5a3.75 3.75 0 115.255.756" />
    <path d="M23.25 9a4.5 4.5 0 01-4.5 4.5h-1.5" />
    <path d="M4.563 10.501H6.75" />
    <path d="M7.063 13.5a1.5 1.5 0 011.5 1.5v3" />
    <path d="M17.25 22.5V18h2.25a3.749 3.749 0 002.8-6.24 4.5 4.5 0 00-3.55-7.26 4.525 4.525 0 00-.812.075 3.75 3.75 0 00-7.438.675c0 .088 0 .175.01.261A4.5 4.5 0 004.563 10.5H4.5a3.75 3.75 0 000 7.5h4.063a3.75 3.75 0 005.687 1.5v3" />
    <path d="M14.25 19.5v-3a1.5 1.5 0 00-1.5-1.5h-1.5" />
  </svg>
)

export default SvgStudyBrain
