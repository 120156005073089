import React from "react"

const SvgEarth1 = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <circle fill="currentColor" cx={12} cy={12} r={11.25} />
    <path d="M7.079 15.75a1.5 1.5 0 001.455-1.864l-.75-3A1.5 1.5 0 006.329 9.75H.976a11.246 11.246 0 004.016 11.042L6 15.75z" />
    <path d="M20.985 5.25h-4.064a1.5 1.5 0 00-1.455 1.136l-.75 3a1.5 1.5 0 001.455 1.864h1.579l.791 4.75a1.5 1.5 0 001.48 1.253h1.925a11.2 11.2 0 00-.961-12z" />
  </svg>
)

export default SvgEarth1
