import React from "react"

const SvgButtonLoopArrow = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <path d="M13.9 15.315a5.61 5.61 0 004.1 2.249 5.25 5.25 0 000-10.5c-3.75 0-6 5.25-6 5.25s-2.25 5.25-6 5.25a5.25 5.25 0 010-10.5 6.615 6.615 0 014.5 2.383" />
    <path d="M9.884 5.748l.616 3.699-3.699.617" />
  </svg>
)

export default SvgButtonLoopArrow
