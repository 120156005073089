import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

type Props = {
  maxWidth?: number
  path: string
  alt: string
}

export const ImageStaticFluid: React.FC<Props> = ({ path, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  // const match = React.useMemo(() => {
  //   const found = data.allFile.edges.find(({ node }) => path === node.relativePath);
  //   return found;
  // }, [data, path]);

  const match = data.allFile.edges.find(
    ({ node }) => path === node.relativePath
  )

  if (!match) {
    console.log("image not found...", path)
    console.log(
      "all images:",
      data.allFile.edges.map(({ node }) => node.relativePath).join("\n")
    )
    return null
  }

  return <Img fluid={match.node.childImageSharp.fluid} alt={alt} />
}
