import React from "react"

const SvgColorBrushPaint = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      strokeLinecap="round"
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    >
      <path d="M16.828 13.555l6.456-9.822h0A1.16 1.16 0 0021.4 2.385l-7.26 9.374" />
      <path d="M8.7 15.62c-2.294-.547-4.4-1.614-4.99-3.587h0A1.8 1.8 0 002 10.749h0a1.494 1.494 0 00-1.481 1.7h0a11.191 11.191 0 009.071 9.579h0a3.142 3.142 0 003.2-1.2" />
      <path d="M9.359 17.713h0a.59.59 0 00.067 1.109c3.932 1.131 6.965-1.417 7.514-3.382h0a2.9 2.9 0 10-5.587-1.56c-.505 1.808-.263 3.099-1.994 3.833z" />
    </g>
  </svg>
)

export default SvgColorBrushPaint
