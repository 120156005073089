import React from "react"

const SvgInformationCircle = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      strokeLinecap="round"
      strokeWidth={1.5}
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    >
      <path d="M14.25 16.5h-.75 0A1.5 1.5 0 0112 15s0 0 0 0v-3.75 0a.75.75 0 00-.75-.75h-.75" />
      <path d="M11.625 6.75h0a.375.375 0 10.375.375v0a.375.375 0 00-.375-.375v0" />
      <path d="M19.955 4.045c4.393 4.393 4.393 11.517 0 15.91-4.393 4.393-11.517 4.393-15.91 0-4.393-4.393-4.393-11.517 0-15.91 4.393-4.393 11.517-4.393 15.91 0" />
    </g>
  </svg>
)

export default SvgInformationCircle
