import React from "react"

const SvgHouse1 = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <path d="M3.753 13.944v8.25h6v-6a1.5 1.5 0 011.5-1.5h1.5a1.5 1.5 0 011.5 1.5v6h6v-8.25" />
    <path d="M.753 12.444L10.942 2.255a1.5 1.5 0 012.122 0l10.189 10.189" />
  </svg>
)

export default SvgHouse1
