import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Colors } from "../../materials/Colors"
import { Fonts } from "../../materials/Fonts"
import { FontSizes } from "../../materials/FontSizes"

interface InputProps {
  hasError?: boolean
  theme?: "main" | "alternate"
}

export const Input = styled("input")<InputProps>`
  box-sizing: border-box;
  display: inline-block;
  appearance: none;
  line-height: 1.3;
  // wcag 2.5.5: Target Size
  // https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  min-height: 44px;
  padding: 0.2em 0.4em;
  // box-shadow: 0 0 0 1px currentColor;
  border-radius: 4px;
  caret-color: currentColor;
  border: 1px solid currentColor;
  font-size: ${FontSizes[4]}px;
  font-family: ${Fonts.sans.family};
  font-weight: ${Fonts.sans.weight};
  color: ${Colors.oc.blue[8]};
  caret-color: currentColor;

  &::placeholder {
    color: ${Colors.oc.gray[7]};
  }

  &:focus {
    outline: none;
    color: ${Colors.oc.blue[9]};
    box-shadow: 0 0 0 3px currentColor;
  }

  ${props =>
    props.hasError &&
    css`
      color: ${Colors.accents.error[7]};
      &:focus {
        color: ${Colors.accents.error[7]};
      }
    `}
  &[disabled] {
    background-color: ${Colors.grays.cool[9]};
    box-shadow: 0px 0px 0px 3px ${Colors.grays.cool[8]};
    color: ${Colors.grays.cool[7]};
  }
`

Input.defaultProps = {
  hasError: false,
}
