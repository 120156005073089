import * as React from "react"

import { Box, BoxProps } from "../elements"
import { Colors } from "../materials"

export const IconContrastSmall: React.FC<
  { bg?: string; object: string } & BoxProps
> = ({ bg = "#fff", object, ...rest }) => (
  <Box
    role="img"
    as="span"
    aria-label="Non-text Contrast"
    css={{
      width: 20,
      height: 20,
      position: "relative",
    }}
    {...rest}
  >
    <Box
      css={{
        borderRadius: "50%",
        border: `1px solid ${Colors.oc.gray[6]}`,
        width: 18,
        height: 18,
        backgroundColor: bg,
        position: "absolute",
        top: 0,
        left: -2,
      }}
    />
    <Box
      css={{
        borderRadius: "50%",
        border: `1px solid ${Colors.oc.gray[6]}`,
        width: 18,
        height: 18,
        backgroundColor: object,
        position: "absolute",
        bottom: -4,
        right: -2,
      }}
    />
  </Box>
)

export const IconContrast: React.FC<
  { bg?: string; object: string } & BoxProps
> = ({ bg = "#fff", object, ...rest }) => (
  <Box
    role="img"
    as="span"
    aria-label="Non-text Contrast"
    css={{
      width: 30,
      height: 30,
      position: "relative",
    }}
    {...rest}
  >
    <Box
      as="span"
      css={{
        borderRadius: "50%",
        border: `1px solid ${Colors.oc.gray[6]}`,
        width: 26,
        height: 26,
        backgroundColor: bg,
        position: "absolute",
        top: 0,
        left: -2,
      }}
    />
    <Box
      as="span"
      css={{
        borderRadius: "50%",
        border: `1px solid ${Colors.oc.gray[6]}`,
        width: 26,
        height: 26,
        backgroundColor: object,
        position: "absolute",
        bottom: -4,
        right: -2,
      }}
    />
  </Box>
)
