import React from "react"

const SvgQuestionHelpMessage = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      strokeLinecap="round"
      strokeWidth={1.5}
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    >
      <path d="M21.75 18.75h-10.5l-6 4.5v-4.5h-3 0a1.5 1.5 0 01-1.5-1.5s0 0 0 0v-15h0a1.5 1.5 0 011.5-1.5h19.5a1.5 1.5 0 011.5 1.5s0 0 0 0v15h0a1.5 1.5 0 01-1.5 1.5z" />
      <path d="M9 6.75h0a3 3 0 114 2.829h0a1.5 1.5 0 00-1 1.415v.256" />
      <path d="M12 14.25h0a.375.375 0 10.375.375v0A.375.375 0 0012 14.25v0" />
    </g>
  </svg>
)

export default SvgQuestionHelpMessage
