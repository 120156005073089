import React from "react"

const SvgArrowLeft1 = props => (
  <svg id="arrow-left-1_svg__Regular" viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <path d="M16.25 23.25L5.53 12.53a.749.749 0 010-1.06L16.25.75" />
  </svg>
)

export default SvgArrowLeft1
