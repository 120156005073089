import * as React from "react"
import { Colors } from "../../materials"
import {
  Box,
  LinkAnchor,
  Code,
  ButtonIcon,
  Input,
  List,
  ListItem,
} from "../../elements"

export const SnippetNavigationLandmarksAriaLabel: React.FC<{}> = () => (
  <Box display="flex" flexWrap="wrap" bg={Colors.oc.gray[1]}>
    <Box
      width={[1, 1, 0.4]}
      display="flex"
      flexDirection="column"
      aria-hidden="true"
    >
      <nav aria-label="Primary">
        <List
          display="flex"
          justifyContent="center"
          bg={Colors.oc.blue[9]}
          p={4}
        >
          <ListItem px={4}>
            <LinkAnchor
              href="#"
              css={{
                color: Colors.oc.white,
                "&:hover": { color: Colors.tw.yellow["200"] },
                "&:focus:not(.focus-visible)": { outline: "none" },
                "&.focus-visible": {
                  outline: `2px solid ${Colors.tw.yellow["200"]}`,
                  outlineOffset: 4,
                },
              }}
            >
              Home
            </LinkAnchor>
          </ListItem>
          <ListItem px={4}>
            <LinkAnchor
              href="#"
              css={{
                color: Colors.oc.white,
                "&:hover": { color: Colors.tw.yellow["200"] },
                "&:focus:not(.focus-visible)": { outline: "none" },
                "&.focus-visible": {
                  outline: `2px solid ${Colors.tw.yellow["200"]}`,
                  outlineOffset: 4,
                },
              }}
            >
              About
            </LinkAnchor>
          </ListItem>
          <ListItem px={4}>
            <LinkAnchor
              href="#"
              css={{
                color: Colors.oc.white,
                "&:hover": { color: Colors.tw.yellow["200"] },
                "&:focus:not(.focus-visible)": { outline: "none" },
                "&.focus-visible": {
                  outline: `2px solid ${Colors.tw.yellow["200"]}`,
                  outlineOffset: 4,
                },
              }}
            >
              Contact
            </LinkAnchor>
          </ListItem>
        </List>
      </nav>
      <Box mt="auto" />
      <nav aria-label="Secondary">
        <List
          display="flex"
          justifyContent="center"
          bg={Colors.oc.blue[0]}
          borderTop={`1px solid ${Colors.oc.blue[1]}`}
          p={4}
        >
          <ListItem px={4}>
            <LinkAnchor href="#" css={{ fontSize: "0.875rem" }}>
              Facebook
            </LinkAnchor>
          </ListItem>
          <ListItem px={4}>
            <LinkAnchor href="#" css={{ fontSize: "0.875rem" }}>
              Twitter
            </LinkAnchor>
          </ListItem>
          <ListItem px={4}>
            <LinkAnchor href="#" css={{ fontSize: "0.875rem" }}>
              LinkedIn
            </LinkAnchor>
          </ListItem>
        </List>
      </nav>
    </Box>
    <Box width={[1, 1, 0.6]}>
      <Code
        codeString={`<nav aria-label=’primary’>
  <ul>
    ...List on links here...
  </ul>
</nav>
<nav aria-label=’secondary’>
  <ul>
    ...List on links here...
  </ul>
</nav>`}
        language="html"
      />
    </Box>
  </Box>
)
