import React from "react"

const SvgFitnessBiceps = props => (
  <svg id="fitness-biceps_svg__Regular" viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <path d="M8.25 9a2.122 2.122 0 010-3" />
    <path d="M22.005 16.754C17.225 22.374 6.092 21 1.592 18c-1.741-1.161-.433-5.838.8-9.19A16.115 16.115 0 015.1 4.085 3 3 0 017.415 3H10.5a3 3 0 010 6H8.25a5.787 5.787 0 00-1.5 4.5c1.781-1.852 4.109-2.75 6.75 0a4.875 4.875 0 118.5 3.254z" />
    <path d="M18.75 12v3" />
    <path d="M17.25 13.5h3" />
  </svg>
)

export default SvgFitnessBiceps
