import React from "react"

const SvgKeyboardRight = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      strokeLinecap="round"
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    >
      <path d="M18.5 7.497l5 5-5 5" />
      <path d="M23.5 12.5H.5" />
    </g>
  </svg>
)

export default SvgKeyboardRight
