import React from "react"

const SvgArrowDown = props => (
  <svg id="arrow-down_svg__Regular" viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <path d="M12 .75v22.5" />
    <path d="M1.5 12.75L12 23.25l10.5-10.5" />
  </svg>
)

export default SvgArrowDown
