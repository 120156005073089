import * as React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"
import { Box } from "../../elements"
import { Colors } from "../../materials"

const blink = keyframes({
  "0%": { opacity: 0.2 },
  "20%": { opacity: 1 },
  "100%": { opacity: 0.2 },
})

const Dot = styled(Box)({
  height: 4,
  width: 4,
  borderRadius: "50%",
  display: "inline-block",
  margin: "0 1px",
  backgroundColor: Colors.oc.gray[6],
  animationName: blink,
  animationDuration: "1.4s",
  animationFillMode: "both",
  animationIterationCount: "infinite",
})

export const LoadingDots: React.FC<{}> = () => (
  <Box display="flex" alignItems="center" height="auto">
    <Dot />
    <Dot css={{ animationDelay: "0.2s" }} />
    <Dot css={{ animationDelay: "0.4s" }} />
  </Box>
)
