import * as React from "react"
import styled from "@emotion/styled"
import slugify from "@sindresorhus/slugify"
import { MDXProvider } from "@mdx-js/react"
import {
  MaxWidths,
  Icons,
  Colors,
  Fonts,
  Spaces,
  Breakpoints,
} from "../../materials"
import { Footer, SEO, Header, TOC, ConsultationTeaser } from "../../components"
import {
  Container,
  Lead,
  Text,
  Box,
  Title,
  SubHeading,
  Heading,
  Code,
} from "../../elements"
import { preToCodeBlock } from "mdx-utils"
import { SectionSubscriptionMinimal } from "../../partials/SectionSubscriptionMinimal"
import isArray from "lodash/isArray"
import isObject from "lodash/isObject"
import { Tree } from "../../components/TOC/TOC"

const LinkWithIcon = styled.a({
  display: "inline-flex",
  alignItems: "center",
  color: "inherit",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  "& svg": {
    visibility: "hidden",
    color: Colors.oc.blue[9],
  },
  "&:focus svg, &:active svg": {
    visibility: "visible",
  },
  "&:focus": {
    outline: `2px solid ${Colors.oc.blue[9]}`,
    outlineOffset: 4,
  },
})

const generateHeadingWithTitle = (Component: (props: any) => JSX.Element) => (
  props: any
) => {
  const text = isArray(props.children)
    ? props.children
        .map(token =>
          typeof token === "object" ? token.props.children : token
        )
        .join(" ")
    : isObject(props.children)
    ? props.children.props.children
    : props.children
  let slug = ""
  try {
    slug = slugify(text)
  } catch (e) {
    console.log("error for", props.children, text)
  }

  return (
    <Box pt={"4em"} id={slug}>
      <Component
        css={{
          "&:hover": {
            color: Colors.oc.blue[9],
          },
          "&:hover a svg": {
            visibility: "visible",
          },
        }}
      >
        <LinkWithIcon href={`#${slug}`}>
          <span>{props.children}</span>
          <Box ml={2} />
          <Icons.SvgInsertLink aria-hidden="true" />
        </LinkWithIcon>
      </Component>
    </Box>
  )
}

export const MarkdownLayout: React.FC<{
  _frontmatter?: {
    seoTitle?: string
    seoURL?: string
    seoImage?: string
    seoImageAlt?: string
    title: string
    description: string
    tags: string
    lead?: string[]
    toc?: Tree
  }
  renderAfter?: () => React.ReactNode
}> = ({ _frontmatter = {}, renderAfter, children }) => {
  const title = _frontmatter.title || "TODO"
  const seoTitle = _frontmatter.seoTitle || "TODO"
  const seoURL = _frontmatter.seoURL || "TODO"
  const seoImage = _frontmatter.seoImage || "TODO"
  const seoImageAlt = _frontmatter.seoImageAlt || "TODO"
  const description = _frontmatter.description || "TODO"
  const lead = _frontmatter.lead || []
  const tags =
    _frontmatter.tags ||
    "accessibility, testing, a11y, audit, compliance, checker, software"
  const toc = _frontmatter.toc || { nodes: [] }

  const components = {
    h1: generateHeadingWithTitle(Title),
    h2: generateHeadingWithTitle(Heading),
    h3: generateHeadingWithTitle(SubHeading),
    p: Text,
    pre: preProps => {
      const props = preToCodeBlock(preProps)
      // if there's a codeString and some props, we passed the test
      if (props) {
        return <Code {...props} />
      } else {
        // it's possible to have a pre without a code in it
        return <pre {...preProps} />
      }
    },
  }
  return (
    <MDXProvider components={components}>
      <SEO
        title={seoTitle}
        description={description}
        keywords={
          tags
            ? tags
            : "accessibility, testing, a11y, audit, compliance, checker, software"
        }
        jsonLd={{
          "@type": "WebPage",
          url: "https://www.aditus.io/",
          name: title,
          description: description,
        }}
      >
        <meta property="og:url" content={seoURL} />
        <meta property="og:image" content={seoImage} />
        <meta property="og:image:alt" content={seoImageAlt} />
        <meta name="twitter:url" content={seoURL} />
        <meta name="twitter:image" content={seoImage} />
        <meta name="twitter:image:alt" content={seoImageAlt} />
      </SEO>
      <Header />
      <main>
        <Container maxWidth="l" display="flex" css={{ position: "relative" }}>
          <Box flex="1 1 auto" css={{ maxWidth: "100%" }}>
            <Box
              py={10}
              css={{
                p: {
                  maxWidth: MaxWidths.copy,
                },
                "p + p": {
                  marginTop: "1.5rem",
                },
                a: {
                  color: Colors.oc.blue[9],
                  fontWeight: Fonts.sansBold.weight,
                  fontFamily: Fonts.sansBold.family,
                },
                table: {
                  borderCollapse: "collapse",
                  border: 0,
                },
                th: {
                  textAlign: "left",
                },
                thead: {
                  borderBottom: `2px solid ${Colors.oc.gray[6]}`,
                },
                ".whitespace-no-wrap": {
                  whiteSpace: "nowrap",
                },
                "th, td": {
                  paddingTop: Spaces[2],
                  paddingBottom: Spaces[2],
                  paddingLeft: Spaces[2],
                  paddingRight: Spaces[2],
                },
                "tbody tr:not(:first-of-type)": {
                  borderTop: `2px solid ${Colors.oc.gray[3]}`,
                },
                td: {
                  verticalAlign: "top",
                },
                [`@media screen and (max-width: ${Breakpoints[1]})`]: {
                  "table, tbody, tr": {
                    display: "flex",
                    flexDirection: "column",
                  },
                  thead: {
                    display: "none",
                  },
                  "th, td": {
                    padding: 0,
                  },
                  tr: {
                    paddingTop: Spaces[4],
                    paddingBottom: Spaces[4],
                  },
                },
                ".no-auto-vr p + p": {
                  marginTop: 0,
                },
              }}
            >
              <Title color={Colors.oc.blue[9]}>{title}</Title>
              <Box mt={3} />
              {lead.map((p, i) => (
                <Lead
                  key={i}
                  mt={i > 0 ? 2 : 0}
                  dangerouslySetInnerHTML={{ __html: p }}
                />
              ))}
              <Box mt={6} display={["block", "block", "block", "none"]}>
                <TOC tree={toc} id="mobile" />
              </Box>
              {children}
            </Box>
          </Box>
          <Box flex="0 0 auto" display={["none", "none", "none", "block"]}>
            <Box pl={[6, 6, 7]} pt={10} css={{ position: "sticky", top: 0 }}>
              <TOC tree={toc} id="desktop" />
            </Box>
          </Box>
        </Container>
      </main>
      {renderAfter ? renderAfter() : null}
      <ConsultationTeaser
        title={() => <>Need help? The first consultation is on us.</>}
        bg={Colors.oc.white}
        nextSectionBg={Colors.oc.blue[1]}
        previousSectionBg={Colors.oc.gray[0]}
        separatorColor={Colors.oc.blue[2]}
      />
      <Footer />
    </MDXProvider>
  )
}
