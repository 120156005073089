import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"
import {
  alignItems,
  AlignItemsProps,
  alignSelf,
  AlignSelfProps,
  background,
  backgroundImage,
  BackgroundImageProps,
  BackgroundProps,
  borderRadius,
  BorderRadiusProps,
  borderRight,
  BorderRightProps,
  borders,
  BordersProps,
  borderTop,
  BorderTopProps,
  bottom,
  BottomProps,
  boxShadow,
  BoxShadowProps,
  color,
  ColorProps,
  display,
  DisplayProps,
  flex,
  flexDirection,
  FlexDirectionProps,
  FlexProps,
  flexWrap,
  FlexWrapProps,
  fontSize,
  FontSizeProps,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  left,
  LeftProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  minWidth,
  MinWidthProps,
  order,
  OrderProps,
  right,
  RightProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  top,
  TopProps,
  width,
  WidthProps,
} from "styled-system"

export type BoxProps = DisplayProps &
  FlexProps &
  FlexWrapProps &
  FlexDirectionProps &
  MinHeightProps &
  AlignItemsProps &
  AlignSelfProps &
  JustifyContentProps &
  OrderProps &
  MinWidthProps &
  MaxWidthProps &
  SpaceProps &
  ColorProps &
  BackgroundProps &
  BackgroundImageProps &
  WidthProps &
  HeightProps &
  TextAlignProps &
  FontSizeProps &
  BordersProps &
  BorderRightProps &
  BorderTopProps &
  BorderRadiusProps &
  TopProps &
  BottomProps &
  LeftProps &
  RightProps &
  BoxShadowProps

export const Box = styled.div<BoxProps>(
  display,
  flex,
  flexWrap,
  flexDirection,
  alignItems,
  alignSelf,
  justifyContent,
  order,
  minHeight,
  minWidth,
  maxWidth,
  space,
  color,
  background,
  backgroundImage,
  width,
  height,
  textAlign,
  fontSize,
  borders,
  borderRight,
  borderTop,
  borderRadius,
  top,
  bottom,
  left,
  right,
  boxShadow
)
