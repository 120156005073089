import React from "react"

const SvgArrowRight1 = props => (
  <svg id="arrow-right-1_svg__Regular" viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
  </svg>
)

export default SvgArrowRight1
