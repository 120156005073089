import * as React from "react"
import styled, { FunctionInterpolation } from "@emotion/styled"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { Colors } from "../../materials"

const styles: FunctionInterpolation<Props> = _props => ({
  appearance: "none",
  cursor: "pointer",
  userSelect: "none",
  border: 0,
  margin: 0,
  padding: 0,
  minWidth: "44px",
  minHeight: "44px",
  width: "44px",
  height: "44px",
  color: Colors.oc.blue[9],
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  background: "transparent",
  "&:active": {
    transform: "translateY(1px)",
  },
  "&:focus:not(.focus-visible)": {
    outline: "none",
  },
  "&.focus-visible": {
    outline: `2px solid ${Colors.oc.blue[9]}`,
    outlineOffset: 4,
  },
  svg: {
    width: "1.5rem",
    height: "auto",
    color: "inherit",
  },
})

// @ts-ignore
const ElButtonGatsby = styled(props => <GatsbyLink {...props} />)<Props>(styles)
// @ts-ignore
const ElButtonAnchor = styled.a<Props>(styles)
// @ts-ignore
const ElButtonButton = styled.button<Props>(styles)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const ButtonIconGatsby: React.FC<
  Props & GatsbyLinkProps<{}>
> = props => <ElButtonGatsby {...props} />

export const ButtonIconAnchor: React.FC<
  Props & React.PropsWithRef<JSX.IntrinsicElements["a"]>
> = props => <ElButtonAnchor {...props} />

export const ButtonIcon: React.FC<
  Props & React.PropsWithRef<JSX.IntrinsicElements["button"]>
> = props => <ElButtonButton {...props} />
