import React from "react"

const SvgArrowRight = props => (
  <svg id="arrow-right_svg__Regular" viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <path d="M.75 12h22.5" />
    <path d="M12.75 22.5L23.25 12 12.75 1.5" />
  </svg>
)

export default SvgArrowRight
