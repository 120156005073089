export type Spaces = number[]

export const Spaces: Spaces = [
  0,
  4,
  8,
  12,
  16,
  24,
  32,
  48,
  64,
  96,
  128,
  160,
  192,
  256,
  384,
  512,
  640,
  768,
]
