// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-articles-aria-aria-current-mdx": () => import("../src/articles/aria/aria-current.mdx" /* webpackChunkName: "component---src-articles-aria-aria-current-mdx" */),
  "component---src-articles-aria-aria-label-mdx": () => import("../src/articles/aria/aria-label.mdx" /* webpackChunkName: "component---src-articles-aria-aria-label-mdx" */),
  "component---src-articles-patterns-breadcrumbs-mdx": () => import("../src/articles/patterns/breadcrumbs.mdx" /* webpackChunkName: "component---src-articles-patterns-breadcrumbs-mdx" */),
  "component---src-articles-patterns-multiple-navigation-landmarks-mdx": () => import("../src/articles/patterns/multiple-navigation-landmarks.mdx" /* webpackChunkName: "component---src-articles-patterns-multiple-navigation-landmarks-mdx" */),
  "component---src-articles-patterns-accordion-mdx": () => import("../src/articles/patterns/accordion.mdx" /* webpackChunkName: "component---src-articles-patterns-accordion-mdx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessibility-audit-tsx": () => import("../src/pages/accessibility-audit.tsx" /* webpackChunkName: "component---src-pages-accessibility-audit-tsx" */),
  "component---src-pages-admin-all-tsx": () => import("../src/pages/admin/all.tsx" /* webpackChunkName: "component---src-pages-admin-all-tsx" */),
  "component---src-pages-admin-latest-tsx": () => import("../src/pages/admin/latest.tsx" /* webpackChunkName: "component---src-pages-admin-latest-tsx" */),
  "component---src-pages-button-contrast-checker-tsx": () => import("../src/pages/button-contrast-checker.tsx" /* webpackChunkName: "component---src-pages-button-contrast-checker-tsx" */),
  "component---src-pages-contrast-and-accessibility-tsx": () => import("../src/pages/contrast-and-accessibility.tsx" /* webpackChunkName: "component---src-pages-contrast-and-accessibility-tsx" */),
  "component---src-pages-free-accessibility-consultation-tsx": () => import("../src/pages/free-accessibility-consultation.tsx" /* webpackChunkName: "component---src-pages-free-accessibility-consultation-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-talks-react-and-accessibility-tsx": () => import("../src/pages/talks/react-and-accessibility.tsx" /* webpackChunkName: "component---src-pages-talks-react-and-accessibility-tsx" */)
}

