import Helmet from "react-helmet";
import { MarkdownLayout } from "../../../../src/partials/MarkdownLayout";
import { Colors, Fonts } from "../../../../src/materials";
import { Label, LinkGatsby, Heading, Container, Box, LinkAnchor, Code, ButtonIcon, Input, List, ListItem, Note, Text, ImageStaticFluid } from "../../../../src/elements";
import { SnippetNavigationLandmarksAriaLabel } from "../../../../src/partials/SnippetNavigationLandmarksAriaLabel";
import { SnippetNavigationLandmarksAriaLabelledby } from "../../../../src/partials/SnippetNavigationLandmarksAriaLabelledby";
import React from 'react';
export default {
  Helmet,
  MarkdownLayout,
  Colors,
  Fonts,
  Label,
  LinkGatsby,
  Heading,
  Container,
  Box,
  LinkAnchor,
  Code,
  ButtonIcon,
  Input,
  List,
  ListItem,
  Note,
  Text,
  ImageStaticFluid,
  SnippetNavigationLandmarksAriaLabel,
  SnippetNavigationLandmarksAriaLabelledby,
  React
};