import * as React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import styled, { FunctionInterpolation } from "@emotion/styled"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { Colors, Fonts } from "../../materials"

export enum LinkArrowTheme {
  MAIN,
  GREEN,
  RED,
}

const COLOR_BY_THEME: Record<LinkArrowTheme, string> = {
  [LinkArrowTheme.MAIN]: Colors.oc.blue[9],
  [LinkArrowTheme.GREEN]: Colors.tw.green["600"],
  [LinkArrowTheme.RED]: Colors.tw.red["600"],
}

const styles: FunctionInterpolation<Props> = ({
  theme = LinkArrowTheme.MAIN,
}) => ({
  // override button
  background: "none",
  border: "none",
  borderRadius: 0,
  padding: 0,
  fontSize: "inherit",
  display: "inline-flex",
  alignItems: "center",
  cursor: "pointer",
  appearance: "none",

  // styles
  fontWeight: Fonts.sansBold.weight,
  fontFamily: Fonts.sansBold.family,
  color: COLOR_BY_THEME[theme],
  position: "relative",
  paddingRight: "1.1em",
  outline: "none",
  textDecoration: "underline",

  // '&:hover': {
  //   textDecoration: 'underline'
  // },

  "&:focus:not(.focus-visible)": {
    outline: "none",
  },
  "&.focus-visible": {
    outline: `2px solid ${Colors.oc.blue[9]}`,
    outlineOffset: 4,
  },

  svg: {
    position: "absolute",
    right: ".2em",
    top: "50%",
    transform: "translateY(calc(-50% + 1px))",
    width: "1em",
    height: "1em",
  },
})

interface Props {
  theme?: LinkArrowTheme
}

const ElLinkArrowGatsby = styled(props => <GatsbyLink {...props} />)<Props>(
  // @ts-ignore
  styles
)
// @ts-ignore
const ElLinkArrowAnchor = styled(props => <OutboundLink {...props} />)<Props>(
  styles
)
// @ts-ignore
const ElLinkArrowButton = styled.button<Props>(styles)

const ArrowRight: React.FC<{}> = () => (
  <svg viewBox="0 0 24 24" aria-hidden="true">
    <path
      d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"
      fill="currentColor"
    />
    <path d="M0-.25h24v24H0z" fill="none" />
  </svg>
)

export const LinkArrowGatsby: React.FC<Props & GatsbyLinkProps<{}>> = ({
  children,
  theme = LinkArrowTheme.MAIN,
  ...rest
}) => (
  <ElLinkArrowGatsby theme={theme} {...rest}>
    {children}
    <ArrowRight />
  </ElLinkArrowGatsby>
)

export const LinkArrowAnchor: React.FC<
  Props & React.PropsWithRef<JSX.IntrinsicElements["a"]>
> = ({ children, theme = LinkArrowTheme.MAIN, ...rest }) => (
  <ElLinkArrowAnchor theme={theme} {...rest}>
    {children}
    <ArrowRight />
  </ElLinkArrowAnchor>
)

export const LinkArrowButton: React.FC<
  Props & React.PropsWithRef<JSX.IntrinsicElements["button"]>
> = ({ children, theme = LinkArrowTheme.MAIN, ...rest }) => (
  <ElLinkArrowButton theme={theme} {...rest}>
    {children}
    <ArrowRight />
  </ElLinkArrowButton>
)
