import * as React from "react"
import { Colors } from "../../materials"
import {
  Box,
  LinkAnchor,
  Code,
  ButtonIcon,
  Input,
  List,
  ListItem,
} from "../../elements"

export const SnippetRegionLandmarks: React.FC<{}> = () => (
  <Box display="flex" flexWrap="wrap" bg={Colors.oc.gray[1]}>
    <Box
      width={[1, 1, 0.4]}
      display="flex"
      flexDirection="column"
      aria-hidden="true"
    >
      <section aria-label="projects">
        <List display="flex" flexWrap="wrap">
          <ListItem p={3} width={1 / 3}>
            <Box p={4} bg={Colors.oc.gray[3]} />
          </ListItem>
          <ListItem p={3} width={1 / 3}>
            <Box p={4} bg={Colors.oc.gray[3]} />
          </ListItem>
          <ListItem p={3} width={1 / 3}>
            <Box p={4} bg={Colors.oc.gray[3]} />
          </ListItem>
        </List>
      </section>
      <Box mt={6} mb="auto" />
      <section aria-label="team">
        <List display="flex" flexWrap="wrap" justifyContent="center">
          <ListItem p={4}>
            <Box p={4} borderRadius={"50%"} bg={Colors.oc.gray[3]} />
          </ListItem>
          <ListItem p={4}>
            <Box p={4} borderRadius={"50%"} bg={Colors.oc.gray[3]} />
          </ListItem>
          <ListItem p={4}>
            <Box p={4} borderRadius={"50%"} bg={Colors.oc.gray[3]} />
          </ListItem>
          <ListItem p={4}>
            <Box p={4} borderRadius={"50%"} bg={Colors.oc.gray[3]} />
          </ListItem>
          <ListItem p={4}>
            <Box p={4} borderRadius={"50%"} bg={Colors.oc.gray[3]} />
          </ListItem>
        </List>
      </section>
    </Box>
    <Box width={[1, 1, 0.6]}>
      <Code
        codeString={`<section aria-label="projects"> 
  ...
</section>
<section aria-label="team"> 
  ...
</section>`}
        language="html"
      />
    </Box>
  </Box>
)
