import * as React from "react"
import { Colors } from "../../materials"
import ContentLoader from "react-content-loader"

interface DonutProps {
  id: string
  title: string
  description: string
  segments: {
    color: string
    percentage: number
    title: string
    description: string
  }[]
  thickness?: number
  bg?: string
  isLoading?: boolean
}

const INITIAL_POINT = 25

export const SvgDonut: React.FC<DonutProps> = ({
  id,
  title,
  description,
  segments,

  bg,
  thickness = 3,
  isLoading = false,
  ...rest
}) => {
  if (isLoading) {
    return (
      <ContentLoader
        speed={2}
        width={42}
        height={42}
        css={{ width: "100%", height: "auto" }}
        primaryColor={Colors.oc.gray[2]}
        secondaryColor={Colors.oc.gray[0]}
        {...rest}
      >
        <circle cx="21" cy="21" r="21" />
      </ContentLoader>
    )
  }
  let cumulativeLength = 0

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 42 42"
      aria-labelledby={`${id}-title ${id}-desc`}
      {...rest}
    >
      <title id={`${id}-title`}>{title}</title>
      <desc id={`${id}-desc`}>{description}</desc>

      {!!bg && <circle cx="21" cy="21" r={16} fill={bg} />}
      {segments.map(({ color, percentage, title, description }, index) => {
        cumulativeLength += index === 0 ? 0 : segments[index - 1].percentage
        return (
          <circle
            key={index}
            cx="21"
            cy="21"
            r={16}
            fill="transparent"
            stroke={color}
            strokeWidth={thickness}
            strokeDasharray={`${percentage} ${100 - percentage}`}
            strokeDashoffset={100 - cumulativeLength + INITIAL_POINT}
          >
            <title id={`donut-segment-${index}-title`}>{title}</title>
            <desc id={`donut-segment-${index}-desc`}>{description}</desc>
          </circle>
        )
      })}
    </svg>
  )
}
