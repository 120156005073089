import React from "react"

const SvgGlassesRetro1 = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <circle fill="currentColor" cx={5.25} cy={13.425} r={4.5} />
    <path d="M.75 7.424a7.467 7.467 0 013-1.349" />
    <path d="M23.25 7.424a7.467 7.467 0 00-3-1.349" />
    <circle fill="currentColor" cx={18.75} cy={13.425} r={4.5} />
    <path d="M9.75 13.425h4.5" />
  </svg>
)

export default SvgGlassesRetro1
