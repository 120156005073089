import React from "react"

const SvgTouchFinger = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <path d="M9 23.25l-2.764-3.11a1.557 1.557 0 012.328-2.069L10.5 20.25v-9a1.5 1.5 0 013 0V18H15a4.5 4.5 0 014.5 4.5v.751" />
    <path d="M16.5 14.216a7.5 7.5 0 10-9 0" />
    <path d="M16.5 14.216a7.5 7.5 0 10-9 0" />
  </svg>
)

export default SvgTouchFinger
