import { css, Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import React, { Fragment } from "react"
import { Theme as ThemeType } from "styled-system"
import { theme as chakraTheme } from "@chakra-ui/core"

import {
  BaseSizePercentage,
  Colors,
  FontFaces,
  Fonts,
  FontSizes,
  globalCssFonts,
  MaxWidths,
  PreFetch,
  Shadows,
  Spaces,
  Breakpoints,
} from "./materials"

export const theme: ThemeType = {
  // TODO: https://rebassjs.org/theming
  fontSizes: FontSizes,

  // TODO
  // @ts-ignore
  colors: Colors,
  space: Spaces,

  // TODO
  // @ts-ignore
  shadows: Shadows,
  sizes: MaxWidths,
  breakpoints: Breakpoints,
  icons: {
    // Add Chakra's icons
    ...chakraTheme.icons,
  },
}

export const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <PreFetch />
      <Global
        styles={css`
          ${FontFaces}
          html {
            box-sizing: border-box;
          }
          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }
          html {
            font-size: ${BaseSizePercentage};
            -webkit-text-size-adjust: ${BaseSizePercentage};
            font-variant-ligatures: none;
            -webkit-font-variant-ligatures: none;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            font-smoothing: antialiased;
            -webkit-font-smoothing: antialiased;
            text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
            font-variant-ligatures: normal;
            max-width: 100vw;
            overflow-x: hidden;
          }
          html,
          body {
            ${globalCssFonts};
            line-height: 1.5;
            margin: 0;
            color: ${Colors.oc.gray[9]};
            max-width: 100%;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p {
            margin: 0;
          }

          code {
            background: ${Colors.oc.yellow[0]};
            padding: 0.1em 0.15em;
            font-family: ${Fonts.mono.family};
            font-weight: ${Fonts.mono.weight};
            // font-size: ${FontSizes[1]}px;
          }

          figure {
            margin: 0;
          }

          .visually-hidden {
            /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
            position: absolute !important;
            height: 1px;
            width: 1px;
            overflow: hidden;
            clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
            clip: rect(1px, 1px, 1px, 1px);
          }
          .visually-hidden.focusable:active,
          .visually-hidden.focusable:focus {
            clip: auto;
            height: auto;
            margin: 0;
            overflow: visible;
            position: static;
            white-space: inherit;
            width: auto;
          }

          .js-focus-visible :focus:not(.focus-visible),
          .js-focus-visible :focus:not([data-focus-visible-added]) {
            outline: none;
          }

          // Trick to make this override reach ui
          [data-reach-skip-link][data-reach-skip-link]:focus {
            z-index: 4;
            background: ${Colors.tw.yellow["200"]};
            border: 2px solid ${Colors.oc.blue[9]};
            color: ${Colors.oc.blue[9]};
            outline: none;
          }

          :root {
            --reach-dialog: 1;
          }

          [data-reach-dialog-overlay] {
            background: hsla(0, 0%, 0%, 0.4);
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            z-index: 3;
          }

          [data-reach-dialog-content] {
            outline: none;
            z-index: 4;
          }

          pre[class*="language-"] {
            margin: 0;
            padding: 1em;
            font-size: .875rem;
            @media screen and (max-width: ${Breakpoints[1]}) {
              font-size: .778rem;
            }
          }
          pre[class*="language-"] .token-line {
            white-space: pre-wrap;
          }



          .gatsby-resp-image-image {
            position: absolute;
            top: 0;
            left:  0;
            right: 0;
            max-width: 100%;
          }

          .gatsby-resp-image-wrapper {
            @media screen and (max-width: ${Breakpoints[1]}) {
              max-width: 100% !important;
            }
          }


          .md-footnote {
            background: ${Colors.oc.gray[1]};
            border: 1px solid ${Colors.oc.gray[3]};
            padding: .2em .2em;
            text-decoration: none;
            color: ${Colors.oc.gray[9]};
            a {
              text-decoration: none;
              color: inherit;
            }
          }

          .anchor-unstyled {
            background: transparent;
            border: 0;
            padding: 0;
          }





          // a11y syntax highlighting (https://github.com/ericwbailey/a11y-syntax-highlighting/blob/master/dist/prism/a11y-dark.css)

          code[class*="language-"],
          pre[class*="language-"] {
            color: #f8f8f2;
            background: none;
            font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
            text-align: left;
            white-space: pre;
            word-spacing: normal;
            word-break: normal;
            word-wrap: normal;
            line-height: 1.5;

            -moz-tab-size: 4;
            -o-tab-size: 4;
            tab-size: 4;

            -webkit-hyphens: none;
            -moz-hyphens: none;
            -ms-hyphens: none;
            hyphens: none;
          }

          /* Code blocks */
          pre[class*="language-"] {
            padding: 1em;
            margin: 0.5em 0;
            overflow: auto;
            border-radius: 0.3em;
          }

          :not(pre) > code[class*="language-"],
          pre[class*="language-"] {
            background: #2b2b2b;
          }

          /* Inline code */
          :not(pre) > code[class*="language-"] {
            padding: 0.1em;
            border-radius: 0.3em;
            white-space: normal;
          }

          .token.comment,
          .token.prolog,
          .token.doctype,
          .token.cdata {
            color: #d4d0ab;
          }

          .token.punctuation {
            color: #fefefe;
          }

          .token.property,
          .token.tag,
          .token.constant,
          .token.symbol,
          .token.deleted {
            color: #ffa07a;
          }

          .token.boolean,
          .token.number {
            color: #00e0e0;
          }

          .token.selector,
          .token.attr-name,
          .token.string,
          .token.char,
          .token.builtin,
          .token.inserted {
            color: #abe338;
          }

          .token.operator,
          .token.entity,
          .token.url,
          .language-css .token.string,
          .style .token.string,
          .token.variable {
            color: #00e0e0;
          }

          .token.atrule,
          .token.attr-value,
          .token.function {
            color: #ffd700;
          }

          .token.keyword {
            color: #00e0e0;
          }

          .token.regex,
          .token.important {
            color: #ffd700;
          }

          .token.important,
          .token.bold {
            font-weight: bold;
          }
          .token.italic {
            font-style: italic;
          }

          .token.entity {
            cursor: help;
          }

          @media screen and (-ms-high-contrast: active) {
            code[class*="language-"],
            pre[class*="language-"] {
              color: windowText;
              background: window;
            }

            :not(pre) > code[class*="language-"],
            pre[class*="language-"] {
              background: window;
            }

            .token.important {
              background: highlight;
              color: window;
              font-weight: normal;
            }

            .token.atrule,
            .token.attr-value,
            .token.function,
            .token.keyword,
            .token.operator,
            .token.selector {
              font-weight: bold;
            }

            .token.attr-value,
            .token.comment,
            .token.doctype,
            .token.function,
            .token.keyword,
            .token.operator,
            .token.property,
            .token.string {
              color: highlight;
            }

            .token.attr-value,
            .token.url {
              font-weight: normal;
            }
          }
        `}
      />
      {children}
    </Fragment>
  </ThemeProvider>
)

export const Boot: React.SFC<{ element: any }> = ({ element }) => {
  return <Theme>{element}</Theme>
}
