import React from "react"

const SvgIcKeyboardArrowRight = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path
      d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z"
      fill="currentColor"
    />
    <path d="M0-.25h48v48H0z" fill="none" />
  </svg>
)

export default SvgIcKeyboardArrowRight
