import React from "react"

const SvgSingleManCircle = props => (
  <svg id="single-man-circle_svg__Regular" viewBox="0 0 24 24" {...props}>
    <defs>
      <style />
    </defs>
    <path d="M7.476 7.084A8.711 8.711 0 0013.75 9.75a8.725 8.725 0 003.45-.71" />
    <circle fill="currentColor" cx={12} cy={9.75} r={5.25} />
    <path d="M18.913 20.876a9.746 9.746 0 00-13.826 0" />
    <circle fill="currentColor" cx={12} cy={12} r={11.25} />
  </svg>
)

export default SvgSingleManCircle
