import * as React from "react"
import { Colors } from "../../materials"
import {
  Box,
  LinkAnchor,
  Code,
  ButtonIcon,
  Input,
  List,
  ListItem,
  SubHeading,
  Text,
  Heading,
} from "../../elements"

export const SnippetNavigationLandmarksAriaLabelledby: React.FC<{}> = () => (
  <Box display="flex" flexWrap="wrap" bg={Colors.oc.gray[1]}>
    <Box
      width={[1, 1, 0.4]}
      display="flex"
      flexDirection="column"
      aria-hidden="true"
    >
      <nav aria-label="Site Menu">
        <List display="flex" bg={Colors.oc.blue[9]} p={4}>
          <ListItem px={4}>
            <LinkAnchor
              href="#"
              css={{
                color: Colors.oc.white,
                "&:hover": { color: Colors.tw.yellow["200"] },
                "&:focus:not(.focus-visible)": { outline: "none" },
                "&.focus-visible": {
                  outline: `2px solid ${Colors.tw.yellow["200"]}`,
                  outlineOffset: 4,
                },
              }}
            >
              Home
            </LinkAnchor>
          </ListItem>
          <ListItem px={4}>
            <LinkAnchor
              href="#"
              css={{
                color: Colors.oc.white,
                "&:hover": { color: Colors.tw.yellow["200"] },
                "&:focus:not(.focus-visible)": { outline: "none" },
                "&.focus-visible": {
                  outline: `2px solid ${Colors.tw.yellow["200"]}`,
                  outlineOffset: 4,
                },
              }}
            >
              About
            </LinkAnchor>
          </ListItem>
          <ListItem px={4}>
            <LinkAnchor
              href="#"
              css={{
                color: Colors.oc.white,
                "&:hover": { color: Colors.tw.yellow["200"] },
                "&:focus:not(.focus-visible)": { outline: "none" },
                "&.focus-visible": {
                  outline: `2px solid ${Colors.tw.yellow["200"]}`,
                  outlineOffset: 4,
                },
              }}
            >
              Contact
            </LinkAnchor>
          </ListItem>
        </List>
      </nav>
      <Box p={5}>
        <Heading>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit.
        </Heading>
        <Text>
          Est nulla nostrum minima tenetur explicabo, ut commodi asperiores
          officiis officia mollitia, in ullam dolore, perferendis cumque
          aspernatur voluptas impedit ad itaque.
        </Text>
        <Box mt={8} />
        <nav aria-labelledby="related-content">
          <SubHeading id="related-content">Related Content</SubHeading>
          <ul>
            <li>
              <LinkAnchor href="#" css={{ fontSize: "0.875rem" }}>
                Another article
              </LinkAnchor>
            </li>
            <li>
              <LinkAnchor href="#" css={{ fontSize: "0.875rem" }}>
                A similar story
              </LinkAnchor>
            </li>
          </ul>
        </nav>
      </Box>
    </Box>
    <Box width={[1, 1, 0.6]}>
      <Code
        codeString={`<nav aria-label="Site Menu">
  <ul>
    ...List on links here...
  </ul>
</nav>

<article>
  <h1>Title</h1>
  ...


  <nav aria-labelledby="id-1">
    <h2 id="id-1">
      Related Content
    </h2>
    <ul>
      ...List on links here...
    </ul>
  </nav>
</article>`}
        language="html"
      />
    </Box>
  </Box>
)
