import * as React from "react"
import { Interpolation } from "@emotion/styled"
import { PropsOf } from "@emotion/styled-base/types/helper"
import { Colors, Icons } from "../../materials"
import {
  Heading,
  SubHeading,
  Text,
  LinkAnchor,
  Box,
  Lead,
} from "../../elements"
export const SectionMessageWithGif: React.FC<{
  title: string
  text: () => React.ReactNode
  icon: keyof Icons
  iconStyle: Interpolation<{}>
  gif?: {
    href: string
    src: string
    alt: string
  }
  dividerProps?: PropsOf<typeof Box>
}> = ({
  title,
  text,
  icon,
  iconStyle,
  gif,
  dividerProps = {
    bg: Colors.oc.gray[6],
    css: {
      filter: `drop-shadow( 0px 2px 2px ${Colors.oc.gray[5]})`,
    },
  },
}) => {
  const TheIcon = Icons[icon] || Icons.SvgLoveIt
  return (
    <Box maxWidth="m" mx="auto">
      <Box
        px={[0, 0, 0, 6]}
        display="flex"
        flexWrap={["wrap", "wrap", "nowrap", "nowrap"]}
      >
        <Box flex="1 1 auto" display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <Box
              width={40}
              display="flex"
              alignItems="center"
              mr={4}
              css={{
                svg: {
                  width: "100%",
                  height: "auto",
                  ...(iconStyle as object),
                },
              }}
              aria-hidden="true"
            >
              <TheIcon />
            </Box>
            <Heading color={Colors.oc.blue[9]}>{title}</Heading>
          </Box>
          <Box mt={4} />
          <Box flex="1 1 auto">{text()}</Box>
        </Box>
        {gif ? (
          <Box
            flex="1 1 auto"
            display="flex"
            flexDirection="column"
            alignItems={["center", "center", "flex-end"]}
          >
            <Box mt={[4, 4, 0]} ml={[0, 0, 4, 4]} />
            <Box
              as="a"
              href={gif.href}
              target="_blank"
              rel="noopener noreferrer"
              width={[1, 360]}
              css={{
                borderRadius: 20,
                overflow: "hidden",
                display: "block",
              }}
            >
              <Box
                as="img"
                display="block"
                width={1}
                src={gif.src}
                alt={gif.alt}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
      {gif ? (
        <Box
          height={2}
          mx={[0, 0, 0, 6]}
          display={["none", "none", "block"]}
          mt={6}
          {...(dividerProps || {})}
        />
      ) : null}
    </Box>
  )
}
