import React from "react"

const SvgPhoneActionsWait = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      strokeLinecap="round"
      strokeWidth={1.5}
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
    >
      <path d="M13.12 20.853l.01.006h0a4.5 4.5 0 005.6-.614l.629-.63h0a1.5 1.5 0 000-2.121l-2.651-2.652h0a1.5 1.5 0 00-2.122 0v0h0a1.5 1.5 0 01-2.121 0L8.221 10.6h0a1.5 1.5 0 010-2.122v0h0a1.5 1.5 0 000-2.121L5.569 3.705h0a1.5 1.5 0 00-2.121 0l-.63.63h0a4.5 4.5 0 00-.614 5.6l.006.01h0a40.62 40.62 0 0010.91 10.908z" />
      <path d="M13.88 3.19v0" />
      <path d="M13.875 3.188h0a.375.375 0 10.375.375v0a.375.375 0 00-.375-.375" />
      <path d="M17.62 3.19v0" />
      <path d="M17.625 3.188h0a.375.375 0 10.375.375v0a.375.375 0 00-.375-.375" />
      <path d="M21.38 3.19v0" />
      <path d="M21.375 3.188h0a.375.375 0 10.375.375v0a.375.375 0 00-.375-.375" />
    </g>
  </svg>
)

export default SvgPhoneActionsWait
