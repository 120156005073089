// export { Avatar } from './Avatar'
export { Box } from "./Box"
import { BoxProps } from "./Box"
export type BoxProps = BoxProps

export {
  ButtonGatsby,
  ButtonAnchor,
  Button,
  ButtonTheme,
  ButtonSize,
} from "./Button"
// export { ButtonIcon } from './ButtonIcon'
// export { Card } from './Card'
export { Container } from "./Container"
export { FormMessage } from "./FormMessage"
export { ImageFluidContainer } from "./ImageFluidContainer"
export { Input } from "./Input"
export { LinkGatsby, LinkAnchor, LinkButton } from "./Link"
// export { default as Flex } from "./Flex";
// export { Icon } from './Icon'
export { List, ListItem, ListOrdered } from "./List"
export { Pill, PillType, PillSize } from "./Pill"
// // export { default as RadioBase } from "./RadioBase";
// // export { default as RadioBox } from "./RadioBox";
export { Tabs, TabList, Tab, TabPanels, TabPanel } from "./Tabs"
export {
  Heading,
  Label,
  Lead,
  SubHeading,
  Text,
  TextBold,
  TextSizeless,
  Title,
} from "./Text"
import { TextProps } from "./Text"
export type TextProps = TextProps
export { Tooltip } from "./Tooltip"
export { FormSingleInput, FormSingleInputTheme } from "./FormSingleInput"
export { DashboardCard, DashboardCardTheme } from "./DashboardCard"
export {
  LinkArrowTheme,
  LinkArrowAnchor,
  LinkArrowButton,
  LinkArrowGatsby,
} from "./LinkArrow"
export { ButtonIconGatsby, ButtonIconAnchor, ButtonIcon } from "./ButtonIcon"
export { ButtonMenu } from "./ButtonMenu"

export { LoadingDots } from "./LoadingDots"
export { Code } from "./Code"
export { Note } from "./Note"
export { ImageStaticFluid } from "./ImageStaticFluid"
