import * as React from "react"
import { Colors } from "../../materials"
import {
  Box,
  LinkAnchor,
  Code,
  ButtonIcon,
  Input,
  List,
  ListItem,
} from "../../elements"

export const SnippetInputWithoutLabel: React.FC<{}> = () => (
  <Box display="flex" flexWrap="wrap" bg={Colors.oc.gray[1]}>
    <Box
      width={[1, 1, 0.4]}
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={4}
      aria-hidden="true"
    >
      <Input aria-label="Your name" placeholder={`What's your name?`} />
    </Box>
    <Box width={[1, 1, 0.6]}>
      <Code
        codeString={`<input
  type="text"
  name="name"
  placeholder="What's your name?"
  aria-label="Your name" />`}
        language="html"
      />
    </Box>
  </Box>
)
