import Helmet from "react-helmet";
import { MarkdownLayout } from "../../../../src/partials/MarkdownLayout";
import { Colors, Fonts, MaxWidths } from "../../../../src/materials";
import { Container, Label, LinkGatsby, Heading, LinkArrowGatsby, Box, LinkAnchor, Code, ButtonIcon, Input, List, ListItem, Note, Text, TextBold } from "../../../../src/elements";
import { SnippetButtonIcon } from "../../../../src/partials/SnippetButtonIcon";
import { SnippetInputWithoutLabel } from "../../../../src/partials/SnippetInputWithoutLabel";
import { SnippetNavigationLandmarksAriaLabel } from "../../../../src/partials/SnippetNavigationLandmarksAriaLabel";
import { SnippetRegionLandmarks } from "../../../../src/partials/SnippetRegionLandmarks";
import React from 'react';
export default {
  Helmet,
  MarkdownLayout,
  Colors,
  Fonts,
  MaxWidths,
  Container,
  Label,
  LinkGatsby,
  Heading,
  LinkArrowGatsby,
  Box,
  LinkAnchor,
  Code,
  ButtonIcon,
  Input,
  List,
  ListItem,
  Note,
  Text,
  TextBold,
  SnippetButtonIcon,
  SnippetInputWithoutLabel,
  SnippetNavigationLandmarksAriaLabel,
  SnippetRegionLandmarks,
  React
};