import * as React from "react"
import { Text, Box, ButtonGatsby, ButtonTheme } from "../../elements"
import { Colors } from "../../materials"
import { SectionMessageWithGif } from "../../components"

type State = {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<{}, State> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })
    window.Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    window.Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      ;<SectionMessageWithGif
        title="Oups!! This is awkward."
        text={() => (
          <Box maxWidth="50ch">
            <Text>
              Something went wrong on our side. We have logged the error and
              will fix it ASAP. We're sorry.
            </Text>
            <Box mt={6} />
            <ButtonGatsby theme={ButtonTheme.MAIN} to="/">
              Go to homepage
            </ButtonGatsby>
          </Box>
        )}
        icon="SvgVideoGamePacmanEnemy"
        iconStyle={{
          path: {
            strokeWidth: 1.5,
            stroke: Colors.oc.gray[9],
          },
          ellipse: {
            stroke: Colors.oc.gray[9],
            fill: Colors.oc.white,
          },
          "path:first-of-type": {
            stroke: Colors.oc.gray[9],
            fill: Colors.tw.red["600"],
          },
        }}
        gif={{
          href: "https://giphy.com/gifs/power-rangers-morphin-OOZLyBA9Euq2I",
          src: "/videos/power-ranger-fail.2.gif",
          alt: "Power Rangers Running GIF",
        }}
        dividerProps={{
          bg: Colors.oc.gray[6],
          css: {
            filter: `drop-shadow( 0px 2px 2px ${Colors.oc.gray[5]})`,
          },
        }}
      />
    }

    return this.props.children
  }
}
