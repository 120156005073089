import styled from "@emotion/styled"
import { Link as GatsbyLink } from "gatsby"
import * as React from "react"
import Logo from "../../../static/logo-dark.inline.svg"
import {
  Box,
  Container,
  LinkAnchor,
  Label,
  LinkGatsby,
  List,
  ListItem,
  Text,
} from "../../elements"
import { Colors } from "../../materials"

const LogoLink = styled(GatsbyLink)`
  display: inline-block;
  color: ${Colors.oc.blue[8]};
  transition: background-color 150ms ease-in, color 150ms ease-in;
  margin-top: 1rem;
  svg {
    color: inherit;
    fill: currentColor;
    display: block;
    width: 120px;
    height: auto;
  }
  &:focus:not(.focus-visible) {
    outline: none;
  }

  &.focus-visible {
    outline: 2px solid ${Colors.oc.blue[9]};
    outline-offset: 4px;
  }
`

export const Footer: React.FC<{}> = () => (
  <Box as="footer" pt={5} pb={10} bg={Colors.oc.blue[1]}>
    <Container maxWidth="l">
      <LogoLink to="/" aria-label="Aditus Homepage">
        <Logo aria-hidden="true" />
      </LogoLink>
      <h2 className="visually-hidden">Footer</h2>
      <Box
        mt={[2, 5]}
        mb={[4, 5]}
        height={1}
        bg={Colors.oc.blue[8]}
        width={60}
      />

      <Box display="flex" flexWrap="wrap" m={[-3, -5]}>
        <Box
          p={[3, 5]}
          width={[1, 1, 2 / 5, 2 / 5]}
          // bg={['red', 'yellow', 'blue', 'green']}
          display="flex"
          flexDirection="column"
        >
          <Text
            as="span"
            fontSize={3}
            color={Colors.oc.blue[9]}
            fontStyle="italic"
          >
            Our mission is to make the web
            <br /> more accessible for everyone.
          </Text>
          <Box mt="auto" mb={5} />
          <Text fontSize={1} color={Colors.oc.gray[9]}>
            &mdash; made in Porto, Portugal.
          </Text>
        </Box>
        <Box
          as="nav"
          aria-label="Secondary"
          flex="1 1 auto"
          display="flex"
          flexWrap="wrap"
        >
          {/* -6 is to pixel perfectly align with the text */}
          <Box p={[3, 5]} width={[1, 1 / 2, 1 / 2, 1 / 2]} mt={[4, -6]}>
            <Label as="h3" fontSize={2}>
              Learn More
            </Label>
            <List>
              <ListItem mt={2}>
                <LinkGatsby to="/button-contrast-checker/">
                  Button Contrast Checker
                </LinkGatsby>
              </ListItem>
              <ListItem mt={2}>
                <LinkGatsby to="/talks/react-and-accessibility/">
                  React and Accessibility
                </LinkGatsby>
              </ListItem>
              <ListItem mt={2}>
                <LinkGatsby to="/accessibility-audit/">
                  Accessibility Audit
                </LinkGatsby>
              </ListItem>
              <ListItem mt={2}>
                <LinkGatsby to="/contrast-and-accessibility/">
                  Contrast and Accessibility
                </LinkGatsby>
              </ListItem>
            </List>
          </Box>
          <Box
            p={[3, 5]}
            pl={[3, 3, 3, 0]}
            width={[1, 1 / 2, 1 / 2, 1 / 2]}
            mt={[4, -6]}
          >
            <Label as="h3" fontSize={2}>
              Follow Us
            </Label>
            <List>
              <ListItem mt={2}>
                <LinkAnchor
                  href="https://www.twitter.com/aditus_a11y"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </LinkAnchor>
              </ListItem>
              <ListItem mt={2}>
                <LinkAnchor
                  href="http://facebook.com/aditus.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </LinkAnchor>
              </ListItem>
              <ListItem mt={2}>
                <LinkAnchor
                  href="https://www.linkedin.com/company/aditus-io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </LinkAnchor>
              </ListItem>
            </List>
          </Box>
        </Box>
        {/* <ConsultationTeaserMinimal /> */}
        {/* <Box p={[3, 5]} width={[1, 1 / 3, 1 / 3, 1 / 4]}> */}
        {/* <Label as="h2" fontSize={2}>Company</Label>
          <List>
            <ListItem mt={2}>
              <LinkGatsby to="/talks">About Us</LinkGatsby>
            </ListItem>
            <ListItem mt={2}>
              <LinkGatsby to="/reviews">Privacy</LinkGatsby>
            </ListItem>
            <ListItem mt={2}>
              <LinkGatsby to="/articles">Accessibility</LinkGatsby>
            </ListItem>
          </List> */}
        {/* </Box> */}
      </Box>
    </Container>
  </Box>
)
