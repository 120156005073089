import * as React from "react"
import { Colors } from "../../materials/Colors"
import { Box, TextBold, Label } from "../../elements"

type Props = {
  title: string
  headingLevel?: string
  labelProps?: any
}

export const Note: React.FC<Props> = ({
  title,
  headingLevel = "h2",
  labelProps = {},
  children,
  ...rest
}) => (
  <Box as="aside" role="note" {...rest}>
    <Box display="flex" mt={6}>
      <Box bg={Colors.oc.blue[0]} py={5} px={6} borderRadius={10}>
        <Label as={headingLevel} {...labelProps}>
          {title}
        </Label>
        <Box css={{ "& > :first-child": { marginTop: 0 } }}>{children}</Box>
      </Box>
    </Box>
  </Box>
)
